import React, { ReactNode } from "react";
import { Outlet, Route } from "react-router-dom";
import { CasesPage } from "../components/LandingPage/CasesPage";
import { ServicesPage } from "../components/LandingPage/ServicesPage";
import { LandingHomePage } from "../components/LandingPage/LandingHomePage";
import { KontaktPage } from "../components/LandingPage/KontaktPage";
import { BlogPage } from "../components/LandingPage/BlogPage";
import { SingleBlogPostPage } from "../components/LandingPage/children/SingleBlogPostPage";
import { DatenschutzPage } from "../components/LandingPage/DatenschutzPage";
import { ImpressumPage } from "../components/LandingPage/ImpressumPage";
import { TheVietMediaPage } from "../components/LandingPage/TheVietMediaPage";
import { Service__WebDesign } from "../components/LandingPage/Service__WebDesign";
import { Service__GraphicDesign } from "../components/LandingPage/Service__GraphicDesign";
import { Service__SocialMedia } from "../components/LandingPage/Service__SocialMedia";
import { Service__NfcCard } from "../components/LandingPage/Service__NfcCard";

interface SingleRoute {
  name: string;
  path: string;
  index?: boolean;
  element: ReactNode;
  children?: SingleRoute[];
}

export const allRoutes: SingleRoute[] = [
  {
    name: "Home",
    path: "home",
    element: <LandingHomePage />,
  },
  {
    name: "Cases",
    path: "cases",
    element: <CasesPage />,
  },
  {
    name: "Services",
    path: "services",
    element: <Outlet />,
    children: [
      {
        name: "Services",
        path: "",
        element: <ServicesPage />,
        index: true,
      },
      {
        name: "Web Design",
        path: "web-design",
        element: <Service__WebDesign />,
      },
      {
        name: "Graphic Design",
        path: "graphic-design",
        element: <Service__GraphicDesign />,
      },
      {
        name: "Social Media",
        path: "social-media",
        element: <Service__SocialMedia />,
      },
      {
        name: "Smart Business Card",
        path: "smart-business-card",
        element: <Service__NfcCard />,
      },
    ],
  },
  {
    name: "Kontakt",
    path: "kontakt",
    element: <KontaktPage />,
  },
  {
    name: "Blog",
    path: "blog",
    element: <Outlet />,
    children: [
      {
        name: "Blog",
        path: "",
        element: <BlogPage />,
        index: true,
      },
      {
        name: "Single",
        path: ":post_title",
        element: <SingleBlogPostPage />,
      },
    ],
  },
  {
    name: "Datenschutz",
    path: "datenschutz",
    element: <DatenschutzPage />,
  },
  {
    name: "Impressum",
    path: "impressum",
    element: <ImpressumPage />,
  },
  {
    name: "The Viet Media",
    path: "thevietmedia",
    element: <TheVietMediaPage />,
  },
];

export function childrenRenderer(children: SingleRoute[]) {
  if (children.length === 0) {
    return null;
  }
  return children.map((single, index) => (
    <Route key={index} path={single.path} element={single.element} />
  ));
}

export function findRouteIndex(children: SingleRoute[]) {
  if (children.length === 0) {
    return null;
  }
  const foundIndex = children.find((item) => item.index);
  if (!foundIndex) return null;

  return <Route index element={foundIndex.element} />;
}

export interface MyParams {
  postUrlName: string;
}
