import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect, useState } from "react";
import {
  topvideo_initial_state,
  TopVideoBlockPositions,
  TopVideoState,
} from "../../store/reducers/topmedia_info.reducer";
import { HomeTopVideo } from "../FE_common/LandingPage/HomeTopVideo";
import { VerticalLines } from "../FE_common/LandingPage/verticallines/vertical_lines";
import { Box } from "@mui/material";
import { FooterOne } from "../FE_common/LandingPage/LandingPageFooter";
import { Col, Container, Row } from "react-bootstrap";
import html_parser from "html-react-parser";
import { HomepagePartnerSection } from "../FE_common/LandingPage/HomepagePartnerSection";

const DatenschutzPageSecondSection = () => {
  const [page_info, set_page_info] = useState<any>(undefined);

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data =
      overall_information_query.data?.overall_information
        ?.datenschutz_page_info;

    if (!data) return;

    set_page_info(data);
  }, [overall_information_query.data]);

  return (
    <>
      {page_info ? (
        <>
          <Box
            style={{
              width: "100vw",
              overflowX: "hidden",
            }}
          >
            <Container fluid className="position-relative px-0">
              <Container
                className="py-5"
                style={{
                  maxWidth: 877,
                }}
              >
                <Row className="jodit-container border-0 mb-4">
                  <Col className="z-10 jodit-wysiwyg">
                    {html_parser(page_info.content)}
                  </Col>
                </Row>
              </Container>
              <VerticalLines bg="#212121" />
            </Container>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const DatenschutzPage = () => {
  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  const [current_video, set_current_video] = useState<TopVideoState>(
    topvideo_initial_state,
  );

  useEffect(() => {
    const data: TopVideoState[] =
      overall_information_query.data?.overall_information?.top_videos;

    if (!data) return;

    const found = data.find(
      (item) => item.block_position == TopVideoBlockPositions.privacy_page,
    );
    if (!found) return;

    set_current_video(found);
  }, [overall_information_query.data]);
  return (
    <>
      <HomeTopVideo top_video_info={current_video} />
      <Container
        fluid
        className="p-0"
        style={{
          overflow: "hidden",
          width: "100vw",
        }}
      >
        <DatenschutzPageSecondSection />
      </Container>
      <HomepagePartnerSection />
      <FooterOne />
    </>
  );
};
