import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { SideText } from "./others";
import { ScrollDownArrowOne } from "./scrolldown_arrow/ScrollDownArrow";
import { useNavigate } from "react-router-dom";
import {
  TopVideoMediaTypes,
  TopVideoState,
} from "../../../store/reducers/topmedia_info.reducer";
import { cdnUrl, externalCdnUrl } from "../../../environments/environments";
import {
  MenuButtonOne,
  toggleServiceItemsMenu,
} from "./MenuButtons/menubuttons";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../models/interface/gql_queries";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

interface TopVideoProps {
  top_video_info: TopVideoState;
}

const MyLogo = () => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const navigate = useNavigate();

  const [block_info, set_block_info] = useState<any>();

  const overall_information_query = useQuery(gql_queries.overall_information);

  useEffect(() => {
    const raw_data =
      overall_information_query.data?.overall_information.app_general_info;
    if (!raw_data) return;
    set_block_info(raw_data);
  }, [overall_information_query.data]);

  return (
    <React.Fragment>
      {block_info ? (
        <>
          <img
            onClick={() => navigate("/home")}
            className="c-pointer"
            src={`${cdnUrl}/${block_info.medias.logo_img.url}`}
            style={{
              height: is_mobile()
                ? block_info.main_logo_height.mobile
                : block_info.main_logo_height.large,
              opacity: 0.85,
              position: "absolute",
              transform: "translateX(-50%)",
            }}
          />
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

const MiddleRow = ({ top_video_info }: { top_video_info: TopVideoState }) => {
  const language = useSelector((e: AppState) => e.app_overall_info.language);
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  const [serviceMenuIsOpen, setServiceMenuIsOpen] = useState(false);

  const nav_is_open = useSelector(
    (e: AppState) => e.app_overall_info.client_right_nav_is_open,
  );

  let destroy$: any;

  useEffect(() => {
    destroy$ = new Subject();

    toggleServiceItemsMenu.pipe(takeUntil(destroy$)).subscribe((res) => {
      setServiceMenuIsOpen(res);
    });

    return () => {
      destroy$.next(false);
      destroy$.unsubscribe();
    };
  });

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const navigate = useNavigate();

  async function next_slide(next: boolean) {
    gsap.to(".myVideoBoxWrapper", {
      opacity: 0,
      y: -200,
      transitionDuration: 0.1,
    });

    setTimeout(() => {
      if (next) {
        navigate(top_video_info.next_link);
      } else {
        navigate(top_video_info.prev_link);
      }
    }, 600);
  }

  const MyNavigator = styled(Box)`
    cursor: pointer;

    i {
      font-size: ${is_mobile() ? "72px" : "132px"};
      color: white;
      transition: all 0.5s ease-in-out;
    }

    :hover {
      .left {
        transform: ${is_mobile()
          ? "translateX(-5px) scale(0.8)"
          : "translateX(-50px) scale(0.8)"};
      }

      .right {
        transform: ${is_mobile()
          ? "translateX(5px) scale(0.8)"
          : "translateX(50px) scale(0.8)"};
      }
    }
  `;

  return (
    <Row className="justify-content-between">
      <Col xs="auto" className="position-relative">
        <Box
          hidden={nav_is_open || serviceMenuIsOpen}
          style={{
            marginLeft: is_mobile() ? 10 : 50,
          }}
        >
          <MyNavigator onClick={() => next_slide(false)}>
            <i className="fa-thin fa-angle-left left" />
          </MyNavigator>
        </Box>
      </Col>
      <Col
        xs="auto"
        className="d-flex justify-content-center align-items-center"
      >
        <Typography
          hidden={nav_is_open || serviceMenuIsOpen}
          fontWeight="bold"
          color="#fff"
          component="div"
          className="text-uppercase position-absolute"
          variant={is_mobile() ? "h3" : "h2"}
          style={{
            fontSize: is_small_phone()
              ? "30px"
              : is_mobile()
              ? "60px"
              : "180px",
            whiteSpace: "nowrap",
          }}
        >
          {top_video_info[language]?.main_text}
        </Typography>
      </Col>
      <Col xs="auto" className="position-relative">
        <Box
          hidden={nav_is_open || serviceMenuIsOpen}
          style={{
            marginRight: is_mobile() ? 10 : 50,
          }}
        >
          <MyNavigator onClick={() => next_slide(true)}>
            <i className="fa-thin fa-angle-right right" />
          </MyNavigator>
        </Box>
      </Col>
    </Row>
  );
};

const BigTextBox = (props: TopVideoProps) => {
  const language = useSelector((e: AppState) => e.app_overall_info.language);

  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  return (
    <>
      <Row className="flex-column justify-content-between h-100">
        <Col xs="auto">
          <Row className="justify-content-between">
            <Col xs="auto" className="position-relative">
              <SideText
                text={props.top_video_info[language]?.title_topleft}
                side="top_left"
              />
            </Col>
            <Col xs="auto">
              <MyLogo />
            </Col>
            <Col xs="auto" className="position-relative">
              <MenuButtonOne />
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <MiddleRow top_video_info={props.top_video_info} />
        </Col>
        <Col xs="auto">
          <Row className="justify-content-between">
            <Col xs="auto" className="position-relative">
              <SideText
                text={props.top_video_info[language]?.title_bottomleft}
                side="bottom_left"
              />
            </Col>
            <Col xs="auto" className="position-relative">
              <Box
                style={{
                  position: "absolute",
                  transform: is_mobile()
                    ? "translate(-50%) scale(0.5)"
                    : "translate(-50%)",
                }}
              >
                <ScrollDownArrowOne />
              </Box>
            </Col>
            <Col xs="auto" />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const waitingBG = "/Thumbnail-logo-website-vietmedia.jpeg";

export const HomeTopVideo = (props: TopVideoProps) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const loadingRef = useRef<any>();
  const bigBoxRef = useRef<any>();

  async function loadedHandler() {
    await gsap.to(loadingRef.current, { display: "none" });
  }

  useEffect(() => {
    if (bigBoxRef && bigBoxRef.current) {
      gsap.fromTo(
        bigBoxRef.current,
        { y: -200, opacity: 0 },
        { y: 0, opacity: 1, duration: 2 },
      );
    }
  });

  const VideoBox = () => (
    <Box
      ref={bigBoxRef}
      className="overflow-hidden position-relative myVideoBoxWrapper"
      style={{
        opacity: 0,
        width: "100vw",
        height: is_small_phone() ? "40vh" : is_mobile() ? "80vh" : "100vh",
      }}
    >
      <Container
        fluid
        className="py-2 py-lg-5 position-absolute start-0 w-100 h-100"
      >
        <BigTextBox {...props} />
        <Box
          className="w-100 h-100 position-absolute start-0 top-0 z-20 bg-black"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: -5,
            background: `url("${waitingBG}") no-repeat center center / cover`,
          }}
          ref={loadingRef}
        />
      </Container>
      {props.top_video_info.medias.main_img.type == TopVideoMediaTypes.image ? (
        <>
          <img
            src={`${externalCdnUrl}/${props.top_video_info.medias.main_img.url.replace(
              "overall_information/",
              "",
            )}`}
            style={{
              minWidth: "100%",
              minHeight: "100%",
              height: is_mobile() ? "100%" : "",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: -10,
              objectFit: "cover",
            }}
            onLoad={() => loadedHandler()}
          />
        </>
      ) : (
        <>
          <video
            autoPlay
            loop
            preload="metadata"
            playsInline
            muted
            style={{
              minWidth: "100%",
              minHeight: "100%",
              height: is_mobile() ? "100%" : "",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: -10,
              objectFit: "cover",
            }}
            data-hd="true"
            onLoadedData={() => loadedHandler()}
          >
            <source
              src={`${externalCdnUrl}/${props.top_video_info.medias.main_img.url.replace(
                "overall_information/",
                "",
              )}`}
              type="video/mp4"
            />
            {`Your browser doesn't support HTML5 video tag.`}
          </video>
        </>
      )}
    </Box>
  );

  return <VideoBox />;
};
