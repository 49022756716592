import { Outlet } from "react-router-dom";
import React from "react";
import { UI_Snackbar } from "./UI_Components/UI_Snackbar";
import { UI_Dialog } from "./UI_Components/UI_Dialog";
import { LanguageSwitcher } from "./LandingPage/children/LanguageSwitcher";
import { MoveToTop } from "./LandingPage/children/MoveToTop";

export const CoreWebLayout: React.FC = () => {
  return (
    <>
      <MoveToTop />
      <UI_Snackbar />
      <UI_Dialog />
      <LanguageSwitcher />
      <Outlet />
    </>
  );
};
