import React, { useEffect, useRef, useState } from "react";
import { FooterMenuList } from "../LandingPageFooter";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store/models/corestore.interface";
import { all_screen_size } from "../../../../store/reducers/screensize.reducer";
import styled from "@emotion/styled";
import { set_app_overall_info } from "../../../../store/reducers/app_core_state.reducer";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { BehaviorSubject, Subject } from "rxjs";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../../models/interface/gql_queries";
import { HomePageSingleBlockState } from "../../../../store/reducers/homepage_singleblock_info.reducer";
import { takeUntil } from "rxjs/operators";

const NavMenu = () => {
  const is_open = useSelector(
    (e: AppState) => e.app_overall_info.client_right_nav_is_open,
  );

  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const main_menu_list = FooterMenuList.filter((item) => item.main).sort(
    (a, b) => {
      if (a.order && b.order) return a.order - b.order;
      return -1;
    },
  );

  const navigate = useNavigate();

  const dispatcher = useDispatch();

  function moveNav(moved: boolean, textClass: string, bgClass: string) {
    if (moved) {
      gsap.to(textClass, { x: -40 });
      gsap.to(bgClass, { x: -40, width: "120%" });
    } else {
      gsap.to(textClass, { x: 0 });
      gsap.to(bgClass, { x: 0, width: 0 });
    }
  }

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  return (
    <>
      {is_open ? (
        <Box
          className="text-end"
          style={{
            position: "absolute",
            right: 30,
            top: is_mobile() ? 50 : 100,
            zIndex: 10000,
          }}
        >
          {main_menu_list.map((item, i) => (
            <React.Fragment key={i}>
              <Box
                className={`mb-${
                  is_mobile() ? "3" : "5"
                } c-pointer position-relative d-flex justify-content-end`}
                data-aos="fade-left"
                data-aos-delay={300 * i}
                onClick={() => {
                  dispatcher(
                    set_app_overall_info({ client_right_nav_is_open: false }),
                  );
                  if (item.hasAction) {
                    item.action();
                  } else {
                    navigate(item.url);
                  }
                }}
                onMouseEnter={() =>
                  moveNav(true, `.nav-text-7h392-${i}`, `.nav-box-7h392-${i}`)
                }
                onMouseLeave={() =>
                  moveNav(false, `.nav-text-7h392-${i}`, `.nav-box-7h392-${i}`)
                }
              >
                <Typography
                  variant={is_mobile() ? "h5" : "h3"}
                  component="div"
                  style={{ width: "max-content" }}
                  color="white"
                  className={`nav-text-7h392-${i} text-uppercase`}
                >
                  {item[lang]}
                </Typography>
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: 0,
                    height: "120%",
                    background: "#000",
                    zIndex: -1,
                  }}
                  className={`nav-box-7h392-${i}`}
                />
              </Box>
            </React.Fragment>
          ))}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export const toggleServiceItemsMenu = new BehaviorSubject<boolean>(false);

export const ServiceItemsMenu = () => {
  const [is_open, set_is_open] = useState(false);

  let destroy$: any;

  useEffect(() => {
    destroy$ = new Subject();

    toggleServiceItemsMenu.pipe(takeUntil(destroy$)).subscribe((res) => {
      set_is_open(res);
    });

    return () => {
      destroy$.next(false);
      destroy$.unsubscribe();
    };
  });

  const overall_information_query = useQuery(gql_queries.overall_information);

  const [all_services, set_all_services] = useState<
    HomePageSingleBlockState[] | undefined
  >(undefined);

  useEffect(() => {
    const allServicesData: HomePageSingleBlockState[] =
      overall_information_query.data?.overall_information?.services_page_blocks;

    if (!allServicesData) return;

    set_all_services(allServicesData);
  }, [overall_information_query.data]);

  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const main_menu_list = () => {
    return all_services
      ? [...all_services].sort((a, b) => {
          if (a.order && b.order) return a.order - b.order;
          return -1;
        })
      : [];
  };

  const navigate = useNavigate();

  const dispatcher = useDispatch();

  function moveNav(moved: boolean, textClass: string, bgClass: string) {
    if (moved) {
      gsap.to(textClass, { x: -40 });
      gsap.to(bgClass, { x: -40, width: "120%" });
    } else {
      gsap.to(textClass, { x: 0 });
      gsap.to(bgClass, { x: 0, width: 0 });
    }
  }

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  return (
    <>
      {is_open && all_services ? (
        <Box
          className="text-end"
          style={{
            position: "absolute",
            right: 30,
            top: is_mobile() ? 50 : 100,
            zIndex: 10000,
          }}
        >
          {main_menu_list()?.map((item, i) => (
            <React.Fragment key={i}>
              <Box
                className={`mb-${
                  is_mobile() ? "3" : "5"
                } c-pointer position-relative d-flex justify-content-end`}
                data-aos="fade-left"
                data-aos-delay={300 * i}
                onClick={() => {
                  navigate(`/services/${item.service_url}`);
                  toggleServiceItemsMenu.next(false);
                  dispatcher(
                    set_app_overall_info({ client_right_nav_is_open: false }),
                  );
                }}
                onMouseEnter={() =>
                  moveNav(true, `.nav-text-7h392-${i}`, `.nav-box-7h392-${i}`)
                }
                onMouseLeave={() =>
                  moveNav(false, `.nav-text-7h392-${i}`, `.nav-box-7h392-${i}`)
                }
              >
                <Typography
                  variant={is_mobile() ? "h5" : "h3"}
                  component="div"
                  style={{ width: "max-content" }}
                  color="white"
                  className={`nav-text-7h392-${i} text-uppercase`}
                >
                  {item[lang].service_info_mainmenu_name}
                </Typography>
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: 0,
                    height: "120%",
                    background: "#000",
                    zIndex: -1,
                  }}
                  className={`nav-box-7h392-${i}`}
                />
              </Box>
            </React.Fragment>
          ))}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export const MenuButtonOne = () => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const ToggleBox = styled(Box)`
    --strokeWidth: 3;
    .myToggle-7h9201 {
      .menu {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        padding: 0;
        position: absolute;
        top: ${is_mobile() ? "-2rem" : "-1rem"};
        right: ${is_mobile() ? "0px" : "10px"};
        transform: ${is_mobile() ? "scale(0.6)" : "scale(0.8)"};
      }
      .line {
        fill: none;
        stroke: white;
        stroke-width: 6;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
          stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      .line1 {
        stroke-dasharray: 60 207;
        stroke-width: var(--strokeWidth);
      }
      .line2 {
        stroke-dasharray: 60 60;
        stroke-width: var(--strokeWidth);
      }
      .line3 {
        stroke-dasharray: 60 207;
        stroke-width: var(--strokeWidth);
      }
      .opened .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: var(--strokeWidth);
      }
      .opened .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: var(--strokeWidth);
      }
      .opened .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: var(--strokeWidth);
      }
    }
  `;

  const buttonRef = useRef<any>(null);

  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher(
      set_app_overall_info({
        client_right_nav_is_open: false,
      }),
    );
  });

  return (
    <>
      <ToggleBox>
        <Box className="myToggle-7h9201">
          <button
            ref={buttonRef}
            className={`menu`}
            onClick={() => {
              const classes = buttonRef.current.classList;
              if (classes.contains("opened")) {
                classes.remove("opened");
                toggleServiceItemsMenu.next(false);
                dispatcher(
                  set_app_overall_info({
                    client_right_nav_is_open: false,
                  }),
                );
              } else {
                classes.add("opened");
                dispatcher(
                  set_app_overall_info({
                    client_right_nav_is_open: true,
                  }),
                );
              }
            }}
            aria-label="Main Menu"
          >
            <svg width="100" height="100" viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 20,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </button>
        </Box>
      </ToggleBox>
      <NavMenu />
      <ServiceItemsMenu />
    </>
  );
};
