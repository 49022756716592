import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppCoreLanguages } from "./app_core_state.reducer";

export enum CasesPageSingleBlockTypes {
  A = "A",
  B = "B",
}

export enum CasesPageMediaTypes {
  image = "image",
  video = "video",
}

export enum CasesPageSingleBlockModes {
  light = "light",
  dark = "dark",
}

export interface CasesPageSingleMediaEntity {
  type: CasesPageMediaTypes;
  url: string;
}

export interface CasesPageSingleBlockContent {
  title: string;
  content: string;
  first_number: string;
  first_description: string;
  second_number: string;
  second_description: string;
}

export enum CasesPageMediaVariants {
  logo_img = "logo_img",
  main_img = "main_img",
  small_img = "small_img",
}

export type CasesPageSingleBlockState = {
  [key in AppCoreLanguages]: CasesPageSingleBlockContent;
} & {
  ID: string;
  mode: CasesPageSingleBlockModes;
  type: CasesPageSingleBlockTypes;
  see_more_link: string;
  medias: {
    [key in CasesPageMediaVariants]: CasesPageSingleMediaEntity;
  };
  order: number;
};

const langContent = (): {
  [key in AppCoreLanguages]: CasesPageSingleBlockContent;
} => {
  const initVal: any = {};
  Object.values(AppCoreLanguages).forEach((item) => {
    Object.assign(initVal, {
      [item]: {
        title: "Title",
        content: "Content is here",
        first_number: "+55%",
        first_description: "First description",
        second_number: "+122%",
        second_description: "Second description",
      },
    });
  });
  return initVal;
};

export const cases_page_singleblock_initial_state: CasesPageSingleBlockState = {
  ID: "5a2",
  ...langContent(),
  medias: {
    logo_img: {
      type: CasesPageMediaTypes.image,
      url: "Sky_X_Logo_white.svg",
    },
    main_img: {
      type: CasesPageMediaTypes.image,
      url: "b29f4ce4-70e4-4953-ac22-8c164879415b_Case_Data_img-1.jpeg",
    },
    small_img: {
      type: CasesPageMediaTypes.image,
      url: "5019ab4a-5cb7-4da0-a4cc-47bcdef2be47_Case_Data_img.jpeg",
    },
  },
  mode: CasesPageSingleBlockModes.light,
  order: 0,
  see_more_link: "home",
  type: CasesPageSingleBlockTypes.A,
};

export const CasesPageSingleBlockSlice = createSlice({
  name: "cases_page_singleblock_info",
  initialState: cases_page_singleblock_initial_state,
  reducers: {
    set_cases_page_singleblock_info: (
      state,
      action: PayloadAction<Partial<CasesPageSingleBlockState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    reset_cases_page_singleblock_state: (state) => {
      Object.assign(state, cases_page_singleblock_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_cases_page_singleblock_info,
  reset_cases_page_singleblock_state,
} = CasesPageSingleBlockSlice.actions;
