import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum HomepagePartnerSectionMediaTypes {
  image = "image",
  video = "video",
}

export interface HomepagePartnerSectionSingleMediaEntity {
  type: HomepagePartnerSectionMediaTypes;
  url: string;
}

export function homepage_partner_section_media_key_array(): string[] {
  const name_array: string[] = [];
  for (let i = 0; i < 30; i++) {
    name_array.push(`img_${i}`);
  }
  return name_array;
}

export interface HomepagePartnerSectionState {
  ID: string;
  medias: {
    [key: string]: HomepagePartnerSectionSingleMediaEntity;
  };
}

export const homepage_partner_section_initial_state: HomepagePartnerSectionState =
  {
    ID: "95455fc0-7ht9-11ed-fg48-0db9d9b9fac1",
    medias: {
      main_img: {
        type: HomepagePartnerSectionMediaTypes.video,
        url: "mixkit-traveling-through-a-tunnel-of-black-cubes-in-3d-31497-medium.mp4",
      },
      ...homepage_partner_section_media_key_array().reduce<{
        [key: string]: HomepagePartnerSectionSingleMediaEntity;
      }>((accumulator, value) => {
        return {
          ...accumulator,
          [value]: {
            type: HomepagePartnerSectionMediaTypes.image,
            url: "",
          },
        };
      }, {}),
    },
  };

export const HomepagePartnerSectionSlice = createSlice({
  name: "homepage_partner_section_info",
  initialState: homepage_partner_section_initial_state,
  reducers: {
    set_homepage_partner_section_info: (
      state,
      action: PayloadAction<Partial<HomepagePartnerSectionState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    reset_homepage_partner_section_state: (state) => {
      Object.assign(state, homepage_partner_section_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_homepage_partner_section_info,
  reset_homepage_partner_section_state,
} = HomepagePartnerSectionSlice.actions;
