import { Container } from "react-bootstrap";
import React from "react";
import "./verticallines.scss";
import { Box } from "@mui/material";

export const VerticalLines = ({ bg }: { bg: string }) => (
  <Box
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
    }}
  >
    <Container className="px-4 position-relative h-100 my-vertical-lines-83h2">
      <div
        className="verticalLines__first__normal"
        style={{
          backgroundColor: bg,
        }}
      />
      <div
        className="verticalLines__second__normal"
        style={{
          backgroundColor: bg,
        }}
      />
      <div
        className="verticalLines__third__normal"
        style={{
          backgroundColor: bg,
        }}
      />
      <div
        className="verticalLines__fourth__normal"
        style={{
          backgroundColor: bg,
        }}
      />
    </Container>
  </Box>
);
