import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { Box, Divider, Typography } from "@mui/material";
import styled from "@emotion/styled";
import React, { Fragment } from "react";
import { HomepagePartnerSection } from "../FE_common/LandingPage/HomepagePartnerSection";
import { FooterOne } from "../FE_common/LandingPage/LandingPageFooter";
import { AppCoreLanguages } from "../../store/reducers/app_core_state.reducer";
import { FormularInput } from "./KontaktPage";

export const TheVietMediaPage = () => {
  const MyLogoBox = styled(Box)`
    &:before {
      content: "";
      background: linear-gradient(
        45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000
      );
      position: absolute;
      top: -4px;
      left: -4px;
      background-size: 400%;
      z-index: -2;
      filter: blur(5px);
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      animation: glowing 20s linear infinite;
      transition: opacity 0.3s ease-in-out;
      border-radius: 10px;
      opacity: 1;
    }

    @keyframes glowing {
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 400% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  `;

  const contact_components = [
    "fa-regular fa-phone",
    "fa-brands fa-facebook-f",
    "fa-brands fa-instagram",
    "fa-regular fa-location-dot",
  ];

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  return (
    <>
      <Container
        style={{ paddingBottom: 200, maxWidth: "100vw" }}
        className="overflow-hidden"
      >
        <Row
          className="position-relative"
          style={{
            marginBottom: 200,
          }}
        >
          <Col>
            <video
              autoPlay
              loop
              preload="metadata"
              playsInline
              muted
              style={{
                width: "100%",
                height: "auto",
                zIndex: -10,
                objectFit: "cover",
              }}
              data-hd="true"
            >
              <source
                src="https://tvm-cdn.imgix.net/ea86b8f0-2c10-11ed-b320-83a20d09d367-3D Animation_Logo_Duy_Version1.1.mp4"
                type="video/mp4"
              />
              {`Your browser doesn't support HTML5 video tag.`}
            </video>
            <MyLogoBox
              className="d-flex justify-content-center align-items-center"
              style={{
                width: 200,
                height: 200,
                borderRadius: "50%",
                position: "absolute",
                bottom: "0",
                left: "50%",
                transform: "translate(-50%, 50%)",
              }}
            >
              <img
                src="/logo_TVM_official.png"
                style={{
                  width: 150,
                  height: "auto",
                }}
              />
            </MyLogoBox>
          </Col>
        </Row>
        <Typography
          variant="h2"
          fontWeight="bold"
          className="text-center"
          style={{
            marginBottom: 100,
          }}
        >
          The Viet Media
        </Typography>
        <Row
          style={{
            marginBottom: 100,
          }}
        >
          {contact_components.map((contact, i) => (
            <Fragment key={i}>
              <Col
                xs="6"
                lg="3"
                className="d-flex justify-content-center mb-5 mb-lg-0"
              >
                <Box
                  className="d-flex justify-content-center align-items-center c-pointer"
                  style={{
                    background: "#000",
                    borderRadius: "50%",
                    width: 100,
                    height: 100,
                  }}
                >
                  <Typography variant="h4" className="text-white">
                    <i className={contact} />
                  </Typography>
                </Box>
              </Col>
            </Fragment>
          ))}
        </Row>
        <Row className="justify-content-center flex-col">
          <Col xs="auto">
            <Typography variant="h4" fontWeight="bold">
              About Us
            </Typography>
          </Col>
        </Row>
        <Row className="py-5">
          <Col>
            <Divider />
          </Col>
        </Row>
        <Row
          className="justify-content-center flex-col text-center"
          style={{
            marginBottom: 100,
          }}
        >
          <Col xs="auto">
            <Typography variant="h6" fontWeight="light">
              Established since 2010, we have experienced in Manicures and
              Pedicures, Acrylic nails, Shellac and SNS. The gorgeous salon is
              the place for not only Nails but also Beauty Services where
              clients are provided eyelash extensions.
            </Typography>
          </Col>
        </Row>
        <Row className="justify-content-center flex-col">
          <Col xs="auto">
            <Typography variant="h4" fontWeight="bold">
              Customer Reviews
            </Typography>
          </Col>
        </Row>
        <Row className="py-5">
          <Col>
            <Divider />
          </Col>
        </Row>
        <Row
          className="justify-content-center flex-col text-center"
          style={{
            marginBottom: 100,
          }}
        >
          <Col xs="auto">
            <Typography variant="h6" fontWeight="light">
              I love coming to do my nails here! The staff are always friendly
              and you feel very well looked after. They are also very clean -
              providing a box for each customer. Highly recommend.
            </Typography>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Typography
              variant="h4"
              fontWeight="bold"
              className="text-uppercase mb-5"
            >
              {(() => {
                switch (lang) {
                  case AppCoreLanguages.de:
                    return "Kontaktformular";
                  case AppCoreLanguages.vi:
                    return "Gửi tin nhắn";
                  case AppCoreLanguages.en:
                    return "Contact form";
                  case AppCoreLanguages.it:
                    return "Modulo di Contatto";
                }
              })()}
            </Typography>
            <FormularInput />
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0 mw-100 overflow-hidden">
        <HomepagePartnerSection />
        <FooterOne />
      </Container>
    </>
  );
};
