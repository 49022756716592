import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppCoreLanguages } from "./app_core_state.reducer";

export interface BlogPostContentType {
  title: string;
  content: string;
  images: string[];
}

export type BlogPostState = {
  [key in AppCoreLanguages]: BlogPostContentType;
} & {
  ID?: number;
  title: string;
  category: number;
  content: string;
  avatar: string;
  search_key: string;
  images: string[];
  touched: boolean;
  is_error: boolean;
};

const langContent = (): {
  [key in AppCoreLanguages]: BlogPostContentType;
} => {
  const initVal: any = {};
  Object.values(AppCoreLanguages).forEach((item) => {
    Object.assign(initVal, {
      [item]: {
        title: "",
        content: "",
        images: [],
      },
    });
  });
  return initVal;
};

export const blog_post_initial_state: BlogPostState = {
  ID: undefined,
  title: "",
  category: 0,
  content: "",
  ...langContent(),
  avatar: "",
  search_key: "",
  images: [],
  touched: false,
  is_error: true,
};

function state_check(state: BlogPostState) {
  state.is_error = !state.avatar || !state.category;
}

export const BlogPostSlice = createSlice({
  name: "blog_post_info",
  initialState: blog_post_initial_state,
  reducers: {
    set_blog_post_info: (
      state,
      action: PayloadAction<Partial<BlogPostState>>,
    ) => {
      Object.assign(state, action.payload);
      state_check(state);
    },
    reset_blog_post_state: (state) => {
      Object.assign(state, blog_post_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_blog_post_info, reset_blog_post_state } =
  BlogPostSlice.actions;
