import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "@emotion/styled";
import { CasesPageSingleBlockTypes } from "../../../store/reducers/cases_page_block_info.reducer";

export const SideBoxFrame = ({
  A,
  B,
  C,
}: {
  A: React.ReactElement;
  B: React.ReactElement;
  C: React.ReactElement;
}) => {
  return (
    <Row className="flex-md-column h-100 justify-content-between gap-md-3">
      <Col xs="4" md="12">
        {A}
      </Col>
      <Col xs="4" md="12">
        {B}
      </Col>
      <Col xs="4" md="12">
        {C}
      </Col>
    </Row>
  );
};

export const BigBoxInner = ({ fluid }: { fluid?: boolean }) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const Result = styled(Container)`
    min-width: ${fluid ? "100%" : "unset"};
    z-index: 10;
    padding: ${is_small_phone() ? "0" : is_mobile() ? "0 1rem" : "0 2rem"};
    position: relative;
    height: 100%;
  `;

  return Result;
};

export const LogoBox = ({
  url,
  bg,
  block_type,
}: {
  url: string;
  bg: string;
  block_type?: CasesPageSingleBlockTypes;
}) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_large() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return true;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_xl() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return true;
      case all_screen_size.xxl:
        return true;
    }
  }

  const LogoContainer = styled(Box)`
    background-color: ${bg};
    top: 10%;
    left: ${is_small_phone()
      ? "30vw"
      : is_mobile()
      ? "5vw"
      : is_large()
      ? "5%"
      : 0};
    position: absolute;
    width: ${is_small_phone()
      ? "40vw"
      : is_mobile()
      ? "20vw"
      : is_large()
      ? "23vw"
      : `${46.25 * 0.18}rem`};
    height: ${is_small_phone()
      ? "40vw"
      : is_mobile()
      ? "20vw"
      : is_large()
      ? "23vw"
      : "18%"};
    transform: ${block_type == CasesPageSingleBlockTypes.A && is_xl()
      ? "translateX(50px)"
      : ""};
  `;

  const LogoItem = styled("img")`
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 23%;
  `;
  return (
    <LogoContainer>
      <LogoItem src={url} />
    </LogoContainer>
  );
};

export const Col5Absolute = ({ component }: { component: JSX.Element }) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_large() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return true;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  return (
    <Col
      xs="12"
      xl="4"
      className="position-relative z-20"
      style={{
        minHeight: is_small_phone()
          ? "150vw"
          : is_mobile()
          ? "100vw"
          : is_large()
          ? "110vw"
          : "46.25rem",
      }}
    >
      {component}
    </Col>
  );
};

export const Col7Absolute = ({ component }: { component: JSX.Element }) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_large() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return true;
      case all_screen_size.xxl:
        return true;
    }
  }

  return (
    <Col
      xs="12"
      xl="7"
      className="z-10 h-fit"
      style={{
        marginTop: is_large() ? 0 : is_phone() ? 0 : "-10rem",
      }}
    >
      {component}
    </Col>
  );
};

export const LargeTextBox = ({
  bg,
  border,
  textCl,
  title,
  description,
  block_type,
}: {
  bg: string;
  border: string;
  textCl: string;
  title: JSX.Element;
  description: JSX.Element;
  block_type?: CasesPageSingleBlockTypes;
}) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_large() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return true;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_xl() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return true;
      case all_screen_size.xxl:
        return true;
    }
  }

  return (
    <Box
      style={{
        border: border,
        width: is_small_phone()
          ? "80vw"
          : is_mobile()
          ? "50vw"
          : is_large()
          ? "50vw"
          : `${46.25 * 0.5}rem`,
        height: is_small_phone()
          ? "80vw"
          : is_mobile()
          ? "50vw"
          : is_large()
          ? "50vw"
          : "50%",
        background: bg,
        minHeight: 300,
        padding: is_mobile() ? "2rem 2rem" : "4rem 2rem",
        position: "absolute",
        top: is_small_phone()
          ? "48vw"
          : is_mobile()
          ? "28vw"
          : is_large()
          ? "30vw"
          : "25%",
        left: is_small_phone()
          ? "10vw"
          : is_mobile()
          ? "20vw"
          : is_large()
          ? "20vw"
          : "16%",
        color: textCl,
        transform:
          block_type == CasesPageSingleBlockTypes.A && is_xl()
            ? "translateX(50px)"
            : "",
      }}
    >
      <Typography
        variant={is_mobile() ? "h5" : "h4"}
        className="text-uppercase mb-2"
      >
        {title}
      </Typography>
      <Typography variant="body1" fontWeight="300">
        {description}
      </Typography>
    </Box>
  );
};

export const BgSmallLogo = ({ url, left }: { url: string; left: string }) => {
  return (
    <img
      src={url}
      style={{
        position: "absolute",
        left: left,
        marginTop: "90%",
        width: "auto",
        height: 220,
        opacity: 0.85,
      }}
    />
  );
};

export const SideText = ({
  side,
  text,
}: {
  side: "top_left" | "bottom_left";
  text: string;
}) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const box_style_top_left = {
    left: is_mobile() ? 10 : 50,
    transform: "rotateZ(-90deg)",
    position: "absolute",
  };

  const box_style_bottom_left = {
    left: is_mobile() ? 10 : 50,
    transform: "rotateZ(-90deg)",
    position: "absolute",
  };

  const text_style_top_left = {
    transform: "translate(-100%)",
    position: "absolute",
    minWidth: "max-content",
  };

  const text_style_bottom_left = {
    position: "absolute",
    minWidth: "max-content",
  };

  function get_box_style() {
    switch (side) {
      case "top_left":
        return box_style_top_left;
      case "bottom_left":
        return box_style_bottom_left;
    }
    return {};
  }

  function get_text_style() {
    switch (side) {
      case "top_left":
        return text_style_top_left;
      case "bottom_left":
        return text_style_bottom_left;
    }
    return {};
  }

  return (
    <>
      <Box style={get_box_style()}>
        <Typography
          variant={is_mobile() ? "body1" : "h5"}
          textTransform="uppercase"
          component="div"
          fontWeight="semi-bold"
          className="text-white m-0"
          style={get_text_style()}
        >
          {text}
        </Typography>
      </Box>
    </>
  );
};
