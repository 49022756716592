import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ContactPageMediaTypes {
  image = "image",
  video = "video",
}

export enum ContactPageMediaVariants {
  logo_img = "logo_img",
}

export interface ContactPageSingleMediaEntity {
  type: ContactPageMediaTypes;
  url: string;
}

export interface ContactPageInfoState {
  ID: string;
  contact_info: string;
  medias: {
    [key in ContactPageMediaVariants]: ContactPageSingleMediaEntity;
  };
}

export const contact_page_info_initial_state: ContactPageInfoState = {
  ID: "da0fae80-4b7c-42b7-b523-c92bb2564e02",
  contact_info: "",
  medias: {
    [ContactPageMediaVariants.logo_img]: {
      type: ContactPageMediaTypes.image,
      url: "facelift-n43j21k.svg",
    },
  },
};

export const ContactPageInfoSlice = createSlice({
  name: "contact_page_info",
  initialState: contact_page_info_initial_state,
  reducers: {
    set_contact_page_info: (
      state,
      action: PayloadAction<Partial<ContactPageInfoState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    reset_contact_page_info_state: (state) => {
      Object.assign(state, contact_page_info_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_contact_page_info, reset_contact_page_info_state } =
  ContactPageInfoSlice.actions;
