import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect, useState } from "react";
import {
  topvideo_initial_state,
  TopVideoBlockPositions,
  TopVideoState,
} from "../../store/reducers/topmedia_info.reducer";
import { HomeTopVideo } from "../FE_common/LandingPage/HomeTopVideo";
import { BgOuterBox } from "../FE_common/LandingPage/BgOuterBox";
import { VerticalLines } from "../FE_common/LandingPage/verticallines/vertical_lines";
import { BigBoxInner } from "../FE_common/LandingPage/others";
import { Box, Button, Typography } from "@mui/material";
import { FooterOne } from "../FE_common/LandingPage/LandingPageFooter";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppState } from "../../store/models/corestore.interface";
import { cdnUrl } from "../../environments/environments";
import { SingleBlogPostEntity } from "../../models/interface/single_blog_post.entity";
import { all_screen_size } from "../../store/reducers/screensize.reducer";
import { MehrErfahrenBtn } from "../FE_common/LandingPage/MehrErfahrenBtn";
import { text_normalizer } from "../shared/text_normalizer";
import { HomepagePartnerSection } from "../FE_common/LandingPage/HomepagePartnerSection";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import { AppCoreLanguages } from "../../store/reducers/app_core_state.reducer";

export const BlogPageSecondSection = ({
  limit,
  is_on_homepage,
  padding,
  is_black,
}: {
  limit?: number;
  is_on_homepage?: boolean;
  padding?: string;
  is_black?: boolean;
}) => {
  const lang = useSelector((e: AppState) => e.app_overall_info.language);
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_large() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return true;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const OuterBoxOne = BgOuterBox({
    bg: is_black ? "#212121" : "#f0f0f0",
    autoHeight: true,
  });

  const InnerBox = BigBoxInner({ fluid: false });

  const blog_post_query = useQuery(gql_queries.all_blog_posts);

  const [all_posts, set_all_posts] = useState<SingleBlogPostEntity[] | null>(
    null,
  );

  useEffect(() => {
    const rawData: SingleBlogPostEntity[] =
      blog_post_query.data?.all_blog_posts;
    if (!rawData) {
      return;
    }
    let data = [...rawData];
    data = data
      .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
      .map((item) => {
        const copy: SingleBlogPostEntity = { ...item };
        if (copy.date) {
          const d = new Date(copy.date);
          if (d.toLocaleString()) {
            copy.date = d.toLocaleString();
          }
        }
        return copy;
      });

    if (limit) data = data.slice(0, limit);

    set_all_posts(data);

    if (blog_post_query.error) {
      console.log(blog_post_query.error);
    }
  }, [blog_post_query]);

  function isOdd(num: number) {
    return !!(num % 2);
  }

  function moveImg(hold: boolean, imgClass: string) {
    if (hold) {
      gsap.to(imgClass, { y: -20, filter: "drop-shadow(2px 4px 6px black)" });
    } else {
      gsap.to(imgClass, { y: 0, filter: "unset" });
    }
  }

  const navigate = useNavigate();

  return (
    <>
      <OuterBoxOne>
        <InnerBox>
          <Box
            style={{
              padding: padding ? padding : "3rem 0",
            }}
          >
            {is_on_homepage ? (
              <>
                <Row>
                  <Col>
                    <Typography
                      variant={is_mobile() ? "h4" : "h2"}
                      fontWeight="bold"
                      className="text-center text-uppercase text-white"
                      style={{
                        marginBottom: "8rem",
                      }}
                    >
                      Blog
                    </Typography>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row>
              {all_posts?.map((item, i) => (
                <Col key={i} xs="12" sm="6" xl="3" className="mb-5 z-10">
                  <Box
                    className="d-flex w-100 flex-column gap-5"
                    style={{
                      marginTop: is_small_phone() ? 0 : isOdd(i) ? "-3rem" : 0,
                    }}
                  >
                    <Box
                      className={`blog-img-h013-${i}`}
                      style={{
                        background: `url("${cdnUrl}/${item.avatar.media_path}") no-repeat center center / cover`,
                        width: "100%",
                        height: is_small_phone()
                          ? 600
                          : is_phone()
                          ? 400
                          : is_mobile()
                          ? 500
                          : is_large()
                          ? 700
                          : 400,
                      }}
                      onMouseEnter={() => moveImg(true, `.blog-img-h013-${i}`)}
                      onMouseLeave={() => moveImg(false, `.blog-img-h013-${i}`)}
                      onClick={() =>
                        navigate(`/blog/${text_normalizer(item[lang].title)}`)
                      }
                    />
                    <MehrErfahrenBtn
                      mainCl={is_black ? "#fff" : "#212121"}
                      secondCl={is_black ? "#212121" : "#fff"}
                      url={`blog/${text_normalizer(item[lang].title)}`}
                    />
                  </Box>
                </Col>
              ))}
            </Row>
            {is_on_homepage ? (
              <>
                <Row
                  style={{
                    marginTop: "4rem",
                  }}
                >
                  <Col className="text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/blog")}
                      style={{
                        minWidth: 300,
                      }}
                    >
                      <Typography className="text-uppercase" variant="h6">
                        {(() => {
                          switch (lang) {
                            case AppCoreLanguages.de:
                              return "Alle schauen";
                            case AppCoreLanguages.vi:
                              return "Tất cả bài viết";
                            case AppCoreLanguages.en:
                              return "All posts";
                            case AppCoreLanguages.it:
                              return "Tutti i post";
                          }
                        })()}
                      </Typography>
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Box>
        </InnerBox>
        <VerticalLines bg={is_black ? "#f0f0f0" : "#212121"} />
      </OuterBoxOne>
    </>
  );
};

export const BlogPage = () => {
  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  const [current_video, set_current_video] = useState<TopVideoState>(
    topvideo_initial_state,
  );

  useEffect(() => {
    const data: TopVideoState[] =
      overall_information_query.data?.overall_information?.top_videos;

    if (!data) return;

    const found = data.find(
      (item) => item.block_position == TopVideoBlockPositions.blog_page,
    );
    if (!found) return;

    set_current_video(found);
  }, [overall_information_query.data]);

  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const OuterBoxDraft = BgOuterBox({
    bg: "#f0f0f0",
    xlHeight: "300px",
    largeHeight: "300px",
    phoneHeight: "100px",
    smallPhoneHeight: "100px",
    tabletHeight: "100px",
  });

  return (
    <>
      <HomeTopVideo top_video_info={current_video} />
      <Container
        fluid
        className="p-0"
        style={{
          overflow: "hidden",
          width: "100vw",
        }}
      >
        <OuterBoxDraft>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "5%",
              maxWidth: "80%",
              transform: "translateY(-50%)",
              zIndex: 10,
            }}
          >
            <Typography
              className="text-uppercase"
              fontWeight="bold"
              variant={is_small_phone() ? "h5" : "h4"}
            >
              NEWS, INSIGHTS, HIGHLIGHTS
            </Typography>
          </Box>
          <VerticalLines bg="#212121" />
        </OuterBoxDraft>
        <BlogPageSecondSection />
        <HomepagePartnerSection />
        <FooterOne />
      </Container>
    </>
  );
};
