import { useEffect } from "react";
import { setScreenSize } from "../../store/reducers/screensize.reducer";
import { useDispatch } from "react-redux";

export const Window_Resize_Detector = () => {
  const dispatcher = useDispatch();

  const getScreenSize = (size: number): string => {
    if (size <= 576) {
      return "xs";
    } else if (size <= 768) {
      return "sm";
    } else if (size <= 992) {
      return "md";
    } else if (size <= 1200) {
      return "lg";
    } else if (size <= 1400) {
      return "xl";
    } else {
      return "xxl";
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatcher(setScreenSize(getScreenSize(window.innerWidth)));
    });
    dispatcher(setScreenSize(getScreenSize(window.innerWidth)));
  });

  return <></>;
};
