import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import {
  AppCoreLanguages,
  set_app_overall_info,
} from "../../../store/reducers/app_core_state.reducer";
import styled from "@emotion/styled";
import gsap from "gsap";

export const LanguageSwitcher = () => {
  const lang = useSelector((e: AppState) => e.app_overall_info.language);
  const dispatcher = useDispatch();

  function change_lang(obj_lang: AppCoreLanguages) {
    if (obj_lang == lang) return;
    gsap.to(".language-box-44455", { display: "none", width: 70, duration: 0 });
    gsap.to(".lang-btn-arr-7393", { display: "none", duration: 0 });
    localStorage.setItem("current_language", obj_lang);
    dispatcher(
      set_app_overall_info({
        language: obj_lang,
      }),
    );
  }

  const getFlag = (obj_lang: AppCoreLanguages) => {
    switch (obj_lang) {
      case AppCoreLanguages.de:
        return "DE";
      case AppCoreLanguages.vi:
        return "VI";
      case AppCoreLanguages.en:
        return "EN";
      case AppCoreLanguages.it:
        return "IT";
    }
  };

  const transformLangBox = (onHold: boolean) => {
    const tl = gsap.timeline();
    if (onHold) {
      tl.to(".curr-lang-btn-6683", { display: "none", duration: 0 })
        .fromTo(
          ".language-box-44455",
          { width: 70 },
          { width: 280, duration: 0 },
        )
        .to(".lang-btn-arr-7393", { display: "flex", duration: 0 });
    } else {
      tl.to(".lang-btn-arr-7393", { display: "none", duration: 0 })
        .fromTo(
          ".language-box-44455",
          { width: 280 },
          { width: 70, duration: 0 },
        )
        .to(".curr-lang-btn-6683", { display: "block", duration: 0 });
    }

    return true;
  };

  const LangButton = styled(Button)`
    width: 70px;
    height: 50px;
  `;

  return (
    <>
      <Box
        style={{
          position: "fixed",
          bottom: "5vh",
          right: 0,
          background: "#000",
          zIndex: 100,
          minWidth: 70,
          minHeight: 60,
        }}
        onMouseEnter={() => transformLangBox(true)}
        onMouseLeave={() => transformLangBox(false)}
        className="language-box-44455 d-flex justify-content-center align-items-center c-pointer"
      >
        <LangButton className="curr-lang-btn-6683">
          <Typography
            component="div"
            className="text-white text-uppercase"
            fontWeight="bold"
            variant="h5"
          >
            {getFlag(lang)}
          </Typography>
        </LangButton>
        <Box className="lang-btn-arr-7393" style={{ display: "none" }}>
          {Object.values(AppCoreLanguages).map((item, i) => (
            <LangButton key={i} onClick={() => change_lang(item)}>
              <Typography
                component="div"
                className="text-white text-uppercase"
                fontWeight="bold"
                variant="h5"
              >
                {getFlag(item)}
              </Typography>
            </LangButton>
          ))}
        </Box>
      </Box>
    </>
  );
};
