import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum FooterPartnerMediaTypes {
  image = "image",
  video = "video",
}

export enum FooterPartnerMediaVariants {
  main_img = "main_img",
}

export interface FooterPartnerSingleMediaEntity {
  type: FooterPartnerMediaTypes;
  url: string;
}

export interface FooterPartnerLogoState {
  ID: string;
  url: string;
  medias: {
    [key in FooterPartnerMediaVariants]: FooterPartnerSingleMediaEntity;
  };
}

export const footer_partnerlogo_initial_state: FooterPartnerLogoState = {
  ID: "5a2",
  url: "google.com",
  medias: {
    [FooterPartnerMediaVariants.main_img]: {
      type: FooterPartnerMediaTypes.image,
      url: "facelift-n43j21k.svg",
    },
  },
};

export const FooterPartnerLogoSlice = createSlice({
  name: "footer_partnerlogo_info",
  initialState: footer_partnerlogo_initial_state,
  reducers: {
    set_footer_partnerlogo_info: (
      state,
      action: PayloadAction<Partial<FooterPartnerLogoState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    reset_footer_partnerlogo_state: (state) => {
      Object.assign(state, footer_partnerlogo_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_footer_partnerlogo_info, reset_footer_partnerlogo_state } =
  FooterPartnerLogoSlice.actions;
