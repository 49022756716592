function api_host() {
  if (process.env.NODE_ENV === "production") {
    return "https://mainserver.thevietmedia.com";
  } else {
    return "http://localhost:8000";
  }
}

function cdn_host() {
  if (process.env.NODE_ENV === "production") {
    return "https://mainserver.thevietmedia.com";
  } else {
    return "http://localhost:8000";
  }
}

function external_cdn_host() {
  if (process.env.NODE_ENV === "production") {
    return "https://tvm-cdn.imgix.net";
  } else {
    return "https://tvm-cdn.imgix.net";
  }
}

function gg_client_id() {
  if (process.env.NODE_ENV === "production") {
    return "230100438513-b8vav5j69b0fqu20s81l9dtv636902g2.apps.googleusercontent.com";
  } else {
    return "230100438513-6dgma9i07f03fmo737oeuk07v49dt9qr.apps.googleusercontent.com";
  }
}

export const apiUrl = api_host();
export const fileUploadApi = cdn_host();
export const cdnUrl = cdn_host();
export const externalCdnUrl = external_cdn_host();
export const SocketIO_Endpoint = "http://localhost:8080";
export const globalSettings = {
  fontPrimary: "Oswald",
  google_client_id: gg_client_id(),
};
