import { createSlice } from "@reduxjs/toolkit";

export const screenSizeSlice = createSlice({
  name: "screenSize",
  initialState: {
    value: null,
  },
  reducers: {
    setScreenSize: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setScreenSize } = screenSizeSlice.actions;

export enum all_screen_size {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}
