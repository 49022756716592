import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { apiUrl } from "./environments/environments";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { reduxStore } from "./store/core.store";
import { CoreWebLayout } from "./components/WebLayOut";
import { LandingHomePage } from "./components/LandingPage/LandingHomePage";
import {
  allRoutes,
  childrenRenderer,
  findRouteIndex,
} from "./models/allRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css/bundle";
import "aos/dist/aos.css";
import { NotFoundPage } from "./components/LandingPage/NotFoundPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

export const graphQLclient = new ApolloClient({
  uri: `${apiUrl}/graphql/`,
  cache: new InMemoryCache(),
  // Enable sending cookies over cross-origin requests
  credentials: "include",
});

root.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <ApolloProvider client={graphQLclient}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<App />}>
              <Route path="/*" element={<CoreWebLayout />}>
                <Route index element={<LandingHomePage />} />
                {allRoutes.map((single, index) => (
                  <Route
                    key={index}
                    path={single.path}
                    element={single.element}
                  >
                    {single.children ? findRouteIndex(single.children) : null}
                    {single.children ? childrenRenderer(single.children) : null}
                  </Route>
                ))}
              </Route>
              <Route path="*" element={<App />}>
                <Route path="*" element={<CoreWebLayout />}>
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
