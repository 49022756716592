import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect, useState } from "react";
import {
  TopVideoBlockPositions,
  TopVideoState,
} from "../../store/reducers/topmedia_info.reducer";
import { HomeTopVideo } from "../FE_common/LandingPage/HomeTopVideo";
import { Col, Container, Row } from "react-bootstrap";
import { FooterOne } from "../FE_common/LandingPage/LandingPageFooter";
import { BgOuterBox } from "../FE_common/LandingPage/BgOuterBox";
import { BigBoxInner } from "../FE_common/LandingPage/others";
import { Button, Typography } from "@mui/material";
import { AppState } from "../../store/models/corestore.interface";
import { useSelector } from "react-redux";
import { VerticalLines } from "../FE_common/LandingPage/verticallines/vertical_lines";
import { useNavigate } from "react-router-dom";

const not_found_content = {
  de: {
    title: "Seite nicht gefunden",
    content: "Es tut uns leid, diese Seite hat keinen Inhalt.",
    button: "Zur Startseite",
  },
  en: {
    title: "Page not found",
    content: "Sorry, this page has no content.",
    button: "Back to Homepage",
  },
  vi: {
    title: "Không tìm thấy trang",
    content: "Xin lỗi, trang này không có nội dung",
    button: "Về trang chủ",
  },
  it: {
    title: "Pagina non trovata",
    content: "Siamo spiacenti, questa pagina non ha contenuto.",
    button: "Torna alla home page",
  },
};

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  const [current_video, set_current_video] = useState<
    TopVideoState | undefined
  >(undefined);

  useEffect(() => {
    const data: TopVideoState[] =
      overall_information_query.data?.overall_information?.top_videos;

    if (!data) return;

    const found = data.find(
      (item) => item.block_position == TopVideoBlockPositions.notfound_page,
    );
    if (!found || found == current_video) return;

    set_current_video(found);
  }, [overall_information_query.data]);

  const OuterBoxOne = BgOuterBox({
    bg: "#f0f0f0",
    autoHeight: true,
  });
  const InnerBox = BigBoxInner({ fluid: false });

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  return (
    <>
      {current_video ? (
        <>
          <HomeTopVideo top_video_info={current_video} />
          <Container
            fluid
            className="p-0"
            style={{
              overflow: "hidden",
              width: "100vw",
            }}
          >
            <OuterBoxOne>
              <InnerBox>
                <Row className="py-5">
                  <Col>
                    <Typography className="text-uppercase mb-3" variant="h4">
                      {not_found_content[lang].title}
                    </Typography>
                    <Typography className="mb-5">
                      {not_found_content[lang].content}
                    </Typography>
                    <Button variant="contained" onClick={() => navigate("/")}>
                      {not_found_content[lang].button}
                    </Button>
                  </Col>
                </Row>
              </InnerBox>
              <VerticalLines bg="#212121" />
            </OuterBoxOne>
            <FooterOne />
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
