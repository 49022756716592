import { FooterOne } from "../FE_common/LandingPage/LandingPageFooter";
import React, { useEffect, useState } from "react";
import { HomeTopVideo } from "../FE_common/LandingPage/HomeTopVideo";
import { CasesPageSingleBlock } from "../FE_common/LandingPage/CasesPageSingleBlock";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import {
  TopVideoBlockPositions,
  TopVideoState,
} from "../../store/reducers/topmedia_info.reducer";
import { CasesPageSingleBlockState } from "../../store/reducers/cases_page_block_info.reducer";
import { Container } from "react-bootstrap";
import { HomepagePartnerSection } from "../FE_common/LandingPage/HomepagePartnerSection";

const CasesPageSecondSection = () => {
  const overall_information_query = useQuery(gql_queries.overall_information);

  const [all_blocks_data, set_all_blocks_data] = useState<
    CasesPageSingleBlockState[] | null
  >(null);

  useEffect(() => {
    const raw_data = overall_information_query.data?.overall_information;
    if (raw_data && raw_data.cases_page_blocks) {
      set_all_blocks_data(raw_data.cases_page_blocks);
    }
  }, [overall_information_query]);

  return (
    <>
      {all_blocks_data ? (
        <>
          {all_blocks_data.map((item, i) => (
            <CasesPageSingleBlock
              key={i}
              blockID={item.ID}
              target_page="cases_page_blocks"
            />
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const CasesPage = () => {
  const overall_information_query = useQuery(gql_queries.overall_information);

  const [current_video, set_current_video] = useState<
    TopVideoState | undefined
  >(undefined);

  useEffect(() => {
    const data: TopVideoState[] =
      overall_information_query.data?.overall_information?.top_videos;

    if (!data) return;

    const found = data.find(
      (item) => item.block_position == TopVideoBlockPositions.cases_page,
    );
    if (!found || found == current_video) return;

    set_current_video(found);
  }, [overall_information_query.data]);

  return (
    <>
      {current_video ? (
        <>
          <HomeTopVideo top_video_info={current_video} />
          <Container
            fluid
            className="p-0"
            style={{
              overflow: "hidden",
              width: "100vw",
            }}
          >
            <CasesPageSecondSection />
            <HomepagePartnerSection />
            <FooterOne />
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
