import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppCoreLanguages } from "./app_core_state.reducer";

export enum MediaContentPositionTypes {
  start = "start",
  center = "center",
  end = "end",
}

export enum HomePageSingleBlockMediaTypes {
  image = "image",
  video = "video",
}

export enum HomePageSingleBlockTypes {
  A = "A",
  B = "B",
  C = "C",
}

export enum HomePageSingleBlockModes {
  light = "light",
  dark = "dark",
}

export interface HomepageSingleMediaEntity {
  type: HomePageSingleBlockMediaTypes;
  url: string;
}

export enum HomepageMediaVariants {
  main_img = "main_img",
  homepage_img = "homepage_img",
  service_landing_img = "service_landing_img",
}

export interface HomePageSingleBlockContent {
  title: string;
  content_bold: string;
  content_light: string;
  service_info_mainmenu_name: string;
}

export type HomePageSingleBlockState = {
  [key in AppCoreLanguages]: HomePageSingleBlockContent;
} & {
  ID: string;
  mode: HomePageSingleBlockModes;
  type: HomePageSingleBlockTypes;
  medias: {
    [key in HomepageMediaVariants]: HomepageSingleMediaEntity;
  };
  media_position: MediaContentPositionTypes;
  media_position_mobile: MediaContentPositionTypes;
  order: number;
  service_url: string;
};

const langContent = (): {
  [key in AppCoreLanguages]: HomePageSingleBlockContent;
} => {
  const initVal: any = {};
  Object.values(AppCoreLanguages).forEach((item) => {
    Object.assign(initVal, {
      [item]: {
        content_bold: "Wir sind das Beste aus zwei Welten.",
        content_light:
          "Kommunikative Durchschlagskraft ist nicht nur unser täglicher Antrieb, sondern vielmehr auch unser Ursprung. Als Joint Venture der Agenturgruppe thjnk und dem führendem Anbieter für Social-Media-Marketing-Tools facelift verschmelzen wir zwei Visionen moderner Kommunikation miteinander. Das ist die Geburtsstunde der Verbindung aus Kreativität und Technologie.",
        title: "THE SOURCE: CREATIVITY AND TECHNOLOGY.",
        service_info_mainmenu_name: "",
      },
    });
  });
  return initVal;
};

export const home_page_singleblock_initial_state: HomePageSingleBlockState = {
  ID: "5a2",
  ...langContent(),
  media_position: MediaContentPositionTypes.center,
  media_position_mobile: MediaContentPositionTypes.center,
  mode: HomePageSingleBlockModes.light,
  type: HomePageSingleBlockTypes.A,
  medias: {
    [HomepageMediaVariants.main_img]: {
      type: HomePageSingleBlockMediaTypes.image,
      url: "default_image_b34jk12.jpeg",
    },
    [HomepageMediaVariants.homepage_img]: {
      type: HomePageSingleBlockMediaTypes.image,
      url: "default_image_b34jk12.jpeg",
    },
    [HomepageMediaVariants.service_landing_img]: {
      type: HomePageSingleBlockMediaTypes.image,
      url: "default_image_b34jk12.jpeg",
    },
  },
  order: 0,
  service_url: "",
};

export const HomepageSingleBlockSlice = createSlice({
  name: "homepage_singleblock_info",
  initialState: home_page_singleblock_initial_state,
  reducers: {
    set_homepage_singleblock_info: (
      state,
      action: PayloadAction<Partial<HomePageSingleBlockState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    reset_homepage_singleblock_state: (state) => {
      Object.assign(state, home_page_singleblock_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_homepage_singleblock_info,
  reset_homepage_singleblock_state,
} = HomepageSingleBlockSlice.actions;
