import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";
import React, { useEffect, useState } from "react";
import gsap from "gsap";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { cdnUrl } from "../../../environments/environments";
import { MenuButtonOne } from "../../FE_common/LandingPage/MenuButtons/menubuttons";
import { SingleBlogPostEntity } from "../../../models/interface/single_blog_post.entity";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../models/interface/gql_queries";

const MyLogo = () => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const navigate = useNavigate();

  const [block_info, set_block_info] = useState<any>();

  const overall_information_query = useQuery(gql_queries.overall_information);

  useEffect(() => {
    const raw_data =
      overall_information_query.data?.overall_information.app_general_info;
    if (!raw_data) return;
    set_block_info(raw_data);
  }, [overall_information_query.data]);

  return (
    <React.Fragment>
      {block_info ? (
        <>
          <img
            onClick={() => navigate("/home")}
            className="c-pointer d-none"
            src={`${cdnUrl}/${block_info.medias.logo_img.url}`}
            style={{
              height: is_mobile() ? "2.75rem" : "3.75rem",
              opacity: 0.85,
              position: "absolute",
              transform: "translateX(-50%)",
            }}
          />
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

const MiddleRow = ({
  blog_post_info,
  next_url,
  prev_url,
}: {
  blog_post_info: SingleBlogPostEntity;
  next_url?: string;
  prev_url?: string;
}) => {
  const nav_is_open = useSelector(
    (e: AppState) => e.app_overall_info.client_right_nav_is_open,
  );

  const lang = useSelector((e: AppState) => e.app_overall_info.language);
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const navigate = useNavigate();

  async function next_slide(next: boolean) {
    gsap.to(".myVideoBoxWrapper", { y: -200, opacity: 0, duration: 0.1 });

    setTimeout(() => {
      if (next && next_url) {
        navigate(next_url);
      } else if (prev_url) {
        navigate(prev_url);
      }
    }, 1200);
  }

  const MyNavigator = styled(Box)`
    cursor: pointer;

    i {
      font-size: ${is_mobile() ? "72px" : "132px"};
      color: ${nav_is_open ? "transparent" : "#fff"};
      transition: all 0.5s ease-in-out;
    }

    :hover {
      .left {
        transform: ${is_mobile()
          ? "translateX(-5px) scale(0.8)"
          : "translateX(-50px) scale(0.8)"};
      }

      .right {
        transform: ${is_mobile()
          ? "translateX(5px) scale(0.8)"
          : "translateX(50px) scale(0.8)"};
      }
    }
  `;

  return (
    <>
      <Row
        className="justify-content-between align-items-center"
        style={{
          padding: "7rem 0",
        }}
      >
        <Col
          xs="2"
          className="position-relative d-flex justify-content-start h-fit"
        >
          {prev_url ? (
            <>
              <Box
                style={{
                  marginLeft: is_mobile() ? 10 : 50,
                }}
              >
                <MyNavigator onClick={() => next_slide(false)}>
                  <i className="fa-thin fa-angle-left left" />
                </MyNavigator>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Col>
        <Col className="h-fit" xs="8">
          <Row className="d-flex flex-column-reverse flex-lg-row align-items-center">
            <Col xs="12" lg="6" className="h-fit">
              <Typography
                fontWeight="bold"
                color="#fff"
                component="div"
                variant={is_mobile() ? "h4" : "h3"}
                className="text-uppercase"
                lineHeight="1.3em"
              >
                {blog_post_info[lang].title}
              </Typography>
            </Col>
            <Col xs="12" lg="6" className="mb-5 mb-lg-0 h-fit">
              <img
                className="mb-2"
                src={`${cdnUrl}/${blog_post_info.avatar.media_path}`}
                style={{
                  width: "100%",
                  objectFit: "contain",
                  maxHeight: "60vh",
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs="2"
          className="position-relative d-flex justify-content-end h-fit"
        >
          {next_url ? (
            <>
              <Box
                style={{
                  marginRight: is_mobile() ? 10 : 50,
                }}
              >
                <MyNavigator onClick={() => next_slide(true)}>
                  <i className="fa-thin fa-angle-right right" />
                </MyNavigator>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
};

export const SingleBlogPostPageTopImage = ({
  blog_post_info,
  next_url,
  prev_url,
}: {
  blog_post_info: SingleBlogPostEntity;
  next_url?: string;
  prev_url?: string;
}) => {
  const VideoBox = () => (
    <Box
      data-aos="fade-down"
      className="overflow-hidden position-relative myVideoBoxWrapper"
      style={{
        width: "100vw",
        minHeight: "80vh",
        background: "#212121",
      }}
    >
      <Container fluid className="py-5">
        <Row className="flex-column justify-content-between">
          <Col xs="auto">
            <Row className="justify-content-between">
              <Col xs="auto" className="position-relative" />
              <Col xs="auto">
                <MyLogo />
              </Col>
              <Col xs="auto" className="position-relative">
                <MenuButtonOne />
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <MiddleRow
              prev_url={prev_url}
              next_url={next_url}
              blog_post_info={blog_post_info}
            />
          </Col>
          <Col xs="auto">
            <Row className="justify-content-between">
              <Col xs="auto" className="position-relative" />
              <Col xs="auto" className="position-relative" />
              <Col xs="auto" />
            </Row>
          </Col>
        </Row>
      </Container>
    </Box>
  );

  return <VideoBox />;
};
