import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

export const SideImgBox = ({ url }: { url: string }) => {
  const img_box_2A_ref = useRef<any>(null);

  const [img_box_height, set_img_box_height] = useState<number>(300);

  window.addEventListener("resize", () => {
    if (img_box_2A_ref && img_box_2A_ref.current) {
      set_img_box_height(img_box_2A_ref.current.offsetWidth);
    }
  });

  useEffect(() => {
    if (img_box_2A_ref && img_box_2A_ref.current) {
      set_img_box_height(img_box_2A_ref.current.offsetWidth);
    }
  }, [img_box_2A_ref]);

  return (
    <Box
      ref={img_box_2A_ref}
      style={{
        width: "100%",
        minWidth: 100,
        height: img_box_height,
        background: `url("${url}") no-repeat center center / cover`,
      }}
    />
  );
};
