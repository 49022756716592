import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../models/interface/gql_queries";
import { SingleBlogPostEntity } from "../../../models/interface/single_blog_post.entity";
import { SingleBlogPostPageTopImage } from "./SingleBlogPostPageTopImage";
import { text_normalizer } from "../../shared/text_normalizer";
import { Col, Container, Row } from "react-bootstrap";
import { VerticalLines } from "../../FE_common/LandingPage/verticallines/vertical_lines";
import html_parser from "html-react-parser";
import { Box, Typography } from "@mui/material";
import gsap from "gsap";
import { HomepagePartnerSection } from "../../FE_common/LandingPage/HomepagePartnerSection";
import { FooterOne } from "../../FE_common/LandingPage/LandingPageFooter";
import { AppCoreLanguages } from "../../../store/reducers/app_core_state.reducer";
import { AppState } from "../../../store/models/corestore.interface";
import { useSelector } from "react-redux";
import { NotFoundPage } from "../NotFoundPage";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";
import { BlogPageSecondSection } from "../BlogPage";

const FourPostSection = () => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  return (
    <>
      <BlogPageSecondSection
        padding={is_mobile() ? "8rem 0" : "10rem 0 6rem"}
        limit={4}
        is_black={true}
      />
    </>
  );
};

export const SingleBlogPostPage = () => {
  const navigate = useNavigate();
  const lang = useSelector((e: AppState) => e.app_overall_info.language);
  const params = useParams();

  const [post_info, set_post_info] = useState<SingleBlogPostEntity | undefined>(
    undefined,
  );

  const [prev_post, set_prev_post] = useState<
    SingleBlogPostEntity | undefined
  >();
  const [next_post, set_next_post] = useState<
    SingleBlogPostEntity | undefined
  >();

  const all_blog_post_query = useQuery(gql_queries.all_blog_posts);

  useEffect(() => {
    const rawData: SingleBlogPostEntity[] =
      all_blog_post_query.data?.all_blog_posts;
    const post_title_param = params.post_title;
    if (rawData && post_title_param) {
      const core_data = [...rawData]
        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
        .map((item) => {
          const copy: SingleBlogPostEntity = { ...item };
          if (copy.date) {
            const d = new Date(copy.date);
            if (d.toLocaleString()) {
              copy.date = d.toLocaleString();
            }
          }
          return copy;
        });

      const found_item: SingleBlogPostEntity | undefined = core_data.find(
        (p) => text_normalizer(p[lang].title) === post_title_param,
      );

      const found_index = core_data.findIndex(
        (p) => text_normalizer(p[lang].title) === post_title_param,
      );

      if (!found_item || found_index < 0) return;

      if (found_index > 0) {
        set_prev_post(core_data[found_index - 1]);
      } else {
        set_prev_post(undefined);
      }
      if (found_index < core_data.length - 1) {
        set_next_post(core_data[found_index + 1]);
      } else {
        set_next_post(undefined);
      }
      set_post_info(found_item);
    }
  }, [all_blog_post_query.data, params.post_title]);

  function postlink_output(str: string) {
    return `/blog/${text_normalizer(str)}`;
  }

  function goback_hover(hold: boolean) {
    const tl = gsap.timeline();
    if (hold) {
      tl.fromTo(
        ".goback-11",
        { y: 0, opacity: 1 },
        { y: -50, opacity: 0 },
      ).fromTo(
        ".goback-11",
        {
          y: 50,
          opacity: 0,
        },
        { y: 0, opacity: 1 },
      );
    } else {
      tl.fromTo(
        ".goback-11",
        { y: 0, opacity: 1 },
        { y: 50, opacity: 0 },
      ).fromTo(".goback-11", { y: -50, opacity: 0 }, { y: 0, opacity: 1 });
    }
  }

  useEffect(() => {
    if (post_info) {
      navigate(`/blog/${text_normalizer(post_info[lang].title)}`);
    }
  }, [lang]);

  return (
    <>
      {post_info ? (
        <>
          <Box
            style={{
              width: "100vw",
              overflowX: "hidden",
            }}
          >
            <SingleBlogPostPageTopImage
              blog_post_info={post_info}
              prev_url={
                prev_post ? postlink_output(prev_post[lang].title) : undefined
              }
              next_url={
                next_post ? postlink_output(next_post[lang].title) : undefined
              }
            />
            <Container fluid className="position-relative px-0">
              <Container style={{ maxWidth: 680, padding: "100px 1rem" }}>
                <Row className="jodit-container border-0 mb-4">
                  <Col className="z-10 jodit-wysiwyg">
                    {html_parser(post_info[lang].content)}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Box
                      className="d-flex justify-content-center align-items-center position-relative"
                      style={{
                        width: 500,
                        height: 70,
                        maxWidth: "100%",
                        border: "black solid 2px",
                      }}
                    >
                      <Box
                        className="z-10 d-flex justify-content-center align-items-center bg-black"
                        style={{
                          width: "15%",
                          height: 70,
                          left: 0,
                          position: "absolute",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          height="40px"
                          viewBox="0 0 800 800"
                        >
                          <g
                            strokeWidth="28"
                            stroke="hsl(0, 0%, 100%)"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            transform="rotate(134, 400, 400)"
                          >
                            <line
                              x1="164.5"
                              y1="164.5"
                              x2="635.5"
                              y2="635.5"
                              markerEnd="url(#SvgjsMarker1224)"
                            />
                          </g>
                          <defs>
                            <marker
                              markerWidth="5"
                              markerHeight="5"
                              refX="2.5"
                              refY="2.5"
                              viewBox="0 0 5 5"
                              orient="auto"
                              id="SvgjsMarker1224"
                            >
                              <polygon
                                points="0,5 2.5,2.5 0,0 5,2.5"
                                fill="hsl(0, 0%, 100%)"
                              />
                            </marker>
                          </defs>
                        </svg>
                      </Box>
                      <Box
                        style={{
                          position: "absolute",
                          right: 0,
                          width: "85%",
                        }}
                        className="z-10 p-0 d-flex justify-content-center align-items-center c-pointer"
                        onMouseEnter={() => goback_hover(true)}
                        onMouseLeave={() => goback_hover(false)}
                        onClick={() => navigate("/blog")}
                      >
                        <Typography
                          component="div"
                          variant="h6"
                          className="goback-11"
                        >
                          {(() => {
                            switch (lang) {
                              case AppCoreLanguages.de:
                                return "Zurück zur Übersicht";
                              case AppCoreLanguages.vi:
                                return "Tất cả bài viết";
                              case AppCoreLanguages.en:
                                return "All Posts";
                              case AppCoreLanguages.it:
                                return "Tutti i messaggi";
                            }
                          })()}
                        </Typography>
                      </Box>
                    </Box>
                  </Col>
                </Row>
              </Container>
              <HomepagePartnerSection />
              <FourPostSection />
              <FooterOne />
              <VerticalLines bg="#212121" />
            </Container>
          </Box>
        </>
      ) : (
        <>
          <NotFoundPage />
        </>
      )}
    </>
  );
};
