import { Components } from "@mui/material";
import { globalSettings } from "../environments/environments";
import { TypographyOptions } from "@mui/material/styles/createTypography";

export const my_custom_components: Components<any> = {
  MuiTextField: {
    styleOverrides: {
      root: {
        width: "100%",
        marginBottom: "1rem",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-notchedOutline": {
          borderRadius: 0,
          border: "#000 solid 2px",
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        padding: "0.5rem",
        borderRadius: 30,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        borderRadius: 0,
        textTransform: "none",
        textDecoration: "none",
        "&:hover": {
          transition: "transform 200ms ease-in-out",
        },
      },
      contained: {
        "&:hover": {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
    },
    variants: [],
  },
};

export const my_custom_font_setup: TypographyOptions = {
  fontFamily: [
    globalSettings.fontPrimary,
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
};
