import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";

export const RechtAngleImg = ({ url }: { url: string }) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const img_box_2A_ref = useRef<any>(null);

  const [img_box_height, set_img_box_height] = useState<number>(300);

  window.addEventListener("resize", () => {
    if (img_box_2A_ref && img_box_2A_ref.current) {
      set_img_box_height(img_box_2A_ref.current.offsetWidth);
    }
  });

  useEffect(() => {
    if (img_box_2A_ref && img_box_2A_ref.current) {
      set_img_box_height(img_box_2A_ref.current.offsetWidth);
    }
  }, [img_box_2A_ref]);

  return (
    <>
      <Box
        ref={img_box_2A_ref}
        className="mb-3"
        style={{
          background: `url("${url}") no-repeat top center / cover`,
          width: is_mobile() ? "100%" : "unset",
          height: is_mobile() ? img_box_height : "100%",
        }}
      />
    </>
  );
};
