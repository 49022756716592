import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import React, { useEffect, useState } from "react";
import {
  topvideo_initial_state,
  TopVideoBlockPositions,
  TopVideoState,
} from "../../store/reducers/topmedia_info.reducer";
import { HomeTopVideo } from "../FE_common/LandingPage/HomeTopVideo";
import { BgOuterBox } from "../FE_common/LandingPage/BgOuterBox";
import { VerticalLines } from "../FE_common/LandingPage/verticallines/vertical_lines";
import { BigBoxInner } from "../FE_common/LandingPage/others";
import { Box, Button, TextField, Typography } from "@mui/material";
import { FooterOne } from "../FE_common/LandingPage/LandingPageFooter";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { set_contact_page_info } from "../../store/reducers/contact_page_info.reducer";
import { AppState } from "../../store/models/corestore.interface";
import { cdnUrl } from "../../environments/environments";
import html_parser from "html-react-parser";
import { api_post_data } from "../../environments/api_handler";
import { AppCoreLanguages } from "../../store/reducers/app_core_state.reducer";
import { useNavigate } from "react-router-dom";
import { toggleSnackbar } from "../UI_Components/UI_Snackbar";

interface FormularInput {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  message: string;
  social_url: string;
}
const formular_init: FormularInput = {
  first_name: "",
  last_name: "",
  phone_number: "",
  message: "",
  email: "",
  social_url: "",
};

const contact_form_labels = {
  de: {
    email: "E-Mail",
    first_name: "Vorname",
    last_name: "Nachname",
    phone_number: "Telefonnummer",
    message: "Ihre Nachricht",
    privacy_msg:
      "Beim Absenden stimmen Sie unseren Richtlinien zur Datenverarbeitung zu.",
    detail_word: "(Details)",
    social_url: "Ihre Sozialnetzwerk-Link",
    submit_btn: "Absenden",
  },
  en: {
    email: "Email",
    first_name: "First name",
    last_name: "Last name",
    phone_number: "Phone number",
    message: "Your message",
    privacy_msg:
      'By clicking "Send" button, you are agreed with our regulations about data treatment.',
    detail_word: "(Details)",
    social_url: "Your social media link",
    submit_btn: "Send",
  },
  vi: {
    email: "Email",
    first_name: "Tên",
    last_name: "Họ",
    phone_number: "Số điện thoại",
    message: "Lời nhắn của bạn",
    privacy_msg: `Khi nhấn nút gửi, là bạn đã đồng ý với các điều khoản thỏa thuận về xử lý thông tin cá nhân của chúng tôi.`,
    detail_word: "(Chi tiết)",
    social_url: "Liên kết mạng xã hội",
    submit_btn: "Gửi",
  },
  it: {
    email: "E-mail",
    first_name: "Nome",
    last_name: "Cognome",
    phone_number: "Numero di telefono",
    message: "Il tuo messaggio",
    privacy_msg: `Cliccando sul pulsante "Invia", accetti il nostro regolamento sul trattamento dei dati.`,
    detail_word: "(Particolari)",
    social_url: "Il tuo collegamento ai social media",
    submit_btn: "Inviare",
  },
};

export const FormularInput = () => {
  const [formular_data, set_formular_data] =
    useState<FormularInput>(formular_init);

  const navigate = useNavigate();

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  return (
    <>
      <Row>
        <Col xs="12" lg="6">
          <Typography variant="h6" className="mb-3">
            {contact_form_labels[lang].first_name}
          </Typography>
          <TextField
            label={contact_form_labels[lang].first_name}
            value={formular_data.first_name}
            onChange={(e) => {
              set_formular_data({
                ...formular_data,
                first_name: e.target.value,
              });
            }}
          />
        </Col>
        <Col xs="12" lg="6">
          <Typography variant="h6" className="mb-3">
            {contact_form_labels[lang].last_name}
          </Typography>
          <TextField
            label={contact_form_labels[lang].last_name}
            value={formular_data.last_name}
            onChange={(e) => {
              set_formular_data({
                ...formular_data,
                last_name: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg="6">
          <Typography variant="h6" className="mb-3">
            {contact_form_labels[lang].email}
          </Typography>
          <TextField
            label={contact_form_labels[lang].email}
            value={formular_data.email}
            onChange={(e) => {
              set_formular_data({
                ...formular_data,
                email: e.target.value,
              });
            }}
          />
        </Col>
        <Col xs="12" lg="6">
          <Typography variant="h6" className="mb-3">
            {contact_form_labels[lang].phone_number}
          </Typography>
          <TextField
            label={contact_form_labels[lang].phone_number}
            value={formular_data.phone_number}
            onChange={(e) => {
              set_formular_data({
                ...formular_data,
                phone_number: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography variant="h6" className="mb-3">
            {contact_form_labels[lang].message}
          </Typography>
          <TextField
            value={formular_data.message}
            multiline
            minRows="5"
            label={contact_form_labels[lang].message}
            onChange={(e) => {
              set_formular_data({
                ...formular_data,
                message: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography variant="h6" className="mb-3">
            {contact_form_labels[lang].social_url}
          </Typography>
          <TextField
            label={contact_form_labels[lang].social_url}
            value={formular_data.social_url}
            onChange={(e) => {
              set_formular_data({
                ...formular_data,
                social_url: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Typography variant="h6" className="mb-3">
        {contact_form_labels[lang].privacy_msg}
        <span
          className="ms-2 fw-bold c-pointer"
          onClick={() => navigate("/datenschutz")}
        >
          {contact_form_labels[lang].detail_word}
        </span>
      </Typography>
      <Button
        variant="contained"
        fullWidth
        onClick={async () => {
          const result = await api_post_data(
            "auth/contact_page_send_mail_to_admin",
            formular_data,
          );
          if (result.status == 200) {
            toggleSnackbar.next("Sent successfully!");
          }
          set_formular_data(formular_init);
        }}
      >
        <Typography variant="h6">
          {contact_form_labels[lang].submit_btn}
        </Typography>
      </Button>
    </>
  );
};

const KontaktPageSecondSection = () => {
  const OuterBoxDraft = BgOuterBox({
    bg: "#f0f0f0",
    xlHeight: "20vh",
    largeHeight: "20vh",
    phoneHeight: "20vh",
    tabletHeight: "20vh",
    smallPhoneHeight: "20vh",
  });
  const OuterBoxOne = BgOuterBox({
    bg: "#f0f0f0",
    autoHeight: true,
  });

  const InnerBox = BigBoxInner({ fluid: false });

  const contact_page_info = useSelector((e: AppState) => e.contact_page_info);

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  const MyBoxOne = () => {
    return (
      <Box className="px-4 px-md-0">
        <Typography
          variant="h4"
          fontWeight="bold"
          className="text-uppercase mb-5"
        >
          {(() => {
            switch (lang) {
              case AppCoreLanguages.de:
                return "Kontakt";
              case AppCoreLanguages.vi:
                return "Thông tin liên hệ";
              case AppCoreLanguages.en:
                return "Contact";
              case AppCoreLanguages.it:
                return "Contatto";
            }
          })()}
        </Typography>
        <img
          src={`${cdnUrl}/${contact_page_info.medias.logo_img.url}`}
          width="150"
          height="auto"
          className="mb-4"
        />
        <Typography component="div">
          {html_parser(contact_page_info.contact_info)}
        </Typography>
      </Box>
    );
  };

  const MyBoxTwo = () => {
    return (
      <Box className="px-4 px-md-0">
        <Typography
          variant="h4"
          fontWeight="bold"
          className="text-uppercase mb-5"
        >
          {(() => {
            switch (lang) {
              case AppCoreLanguages.de:
                return "Kontaktformular";
              case AppCoreLanguages.vi:
                return "Gửi tin nhắn";
              case AppCoreLanguages.en:
                return "Contact form";
              case AppCoreLanguages.it:
                return "Modulo di Contatto";
            }
          })()}
        </Typography>
        <FormularInput />
      </Box>
    );
  };

  return (
    <>
      <OuterBoxDraft>
        <VerticalLines bg="#212121" />
      </OuterBoxDraft>
      <OuterBoxOne>
        <InnerBox>
          <Row className="py-5">
            <Col xs="12" md="5" className="mb-5 mb-md-0 z-10">
              <MyBoxOne />
            </Col>
            <Col xs="12" md="7" className="z-10">
              <MyBoxTwo />
            </Col>
          </Row>
        </InnerBox>
        <VerticalLines bg="#212121" />
      </OuterBoxOne>
    </>
  );
};

export const KontaktPage = () => {
  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const data =
      overall_information_query.data?.overall_information?.contact_page_info;

    if (!data) return;

    dispatcher(set_contact_page_info(data));
  }, [overall_information_query.data]);

  const [current_video, set_current_video] = useState<TopVideoState>(
    topvideo_initial_state,
  );

  useEffect(() => {
    const data: TopVideoState[] =
      overall_information_query.data?.overall_information?.top_videos;

    if (!data) return;

    const found = data.find(
      (item) => item.block_position == TopVideoBlockPositions.contact_page,
    );
    if (!found) return;

    set_current_video(found);
  }, [overall_information_query.data]);
  return (
    <>
      <HomeTopVideo top_video_info={current_video} />
      <Container
        fluid
        className="p-0"
        style={{
          overflow: "hidden",
          width: "100vw",
        }}
      >
        <KontaktPageSecondSection />
      </Container>
      <FooterOne />
    </>
  );
};
