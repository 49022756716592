import axios, { AxiosRequestConfig } from "axios";
import { apiUrl } from "./environments";
import { toggleSnackbar } from "../components/UI_Components/UI_Snackbar";

export const api_post_data = async (
  url_suffix: string,
  data?: any,
  options?: AxiosRequestConfig,
) => {
  const basic_options: AxiosRequestConfig = {
    withCredentials: true,
  };

  const myConfig = (): AxiosRequestConfig => {
    if (options) {
      return Object.assign(basic_options, options);
    } else {
      return basic_options;
    }
  };

  return axios.post(`${apiUrl}/${url_suffix}`, data, myConfig());
};

axios.interceptors.response.use(
  (res: any) => {
    if (res.data) {
      if (res.data.message) {
        toggleSnackbar.next(res.data.message);
      }
    }
    return res;
  },
  async (error: any) => {
    if (error.response.data) {
      const data = error.response.data;
      if (data.message) toggleSnackbar.next(data.message);
    }

    console.log(error.response);
    return Promise.reject(error);
  },
);
