import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  my_custom_components,
  my_custom_font_setup,
} from "../../models/custom_mui";

export enum AppCoreLanguages {
  de = "de",
  vi = "vi",
  en = "en",
  it = "it",
}

export interface AppOverallState {
  language: AppCoreLanguages;
  client_right_nav_is_open: boolean;
  theme_options_purity_admin_dashboard: any;
  theme_options_client_FE: any;
}

export const initial_purity_dashboard_main_light_theme_options: any = {
  palette: {
    mode: "light",
    primary: {
      main: "#4FD1C5",
      contrastText: "rgba(255,255,255,0.87)",
    },
    secondary: {
      main: "#af87ff",
      contrastText: "rgba(255,255,255,0.87)",
    },
    background: {
      paper: "#ffffff",
      default: "#ffffff",
    },
    success: {
      main: "#0de219",
      contrastText: "#ffffff",
      dark: "#06f115",
    },
    text: {
      primary: "rgba(0,46,109,0.56)",
      secondary: "rgba(52,74,154,0.49)",
    },
    error: {
      main: "#fb8f89",
      contrastText: "#ffffff",
      dark: "#ff2d2d",
    },
    warning: {
      main: "#ffba54",
      contrastText: "rgba(255,255,255,0.87)",
    },
  },
  typography: my_custom_font_setup,
  components: my_custom_components,
};

const initial_theme_options_client_FE = {
  palette: {
    mode: "light",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#fff",
    },
    background: {
      paper: "#ffffff",
      default: "#ffffff",
    },
  },
  typography: my_custom_font_setup,
  components: my_custom_components,
};

const current_language: any = localStorage.getItem("current_language");

const AppOverallInitialValue: AppOverallState = {
  language:
    current_language && Object.keys(AppCoreLanguages).includes(current_language)
      ? current_language
      : AppCoreLanguages.de,
  client_right_nav_is_open: false,
  theme_options_purity_admin_dashboard:
    initial_purity_dashboard_main_light_theme_options,
  theme_options_client_FE: initial_theme_options_client_FE,
};

export const AppOverallInfoSlice = createSlice({
  name: "app_overall_info",
  initialState: AppOverallInitialValue,
  reducers: {
    set_app_overall_info: (
      state,
      action: PayloadAction<Partial<AppOverallState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    reset_app_overall_state: (state) => {
      Object.assign(state, AppOverallInitialValue);
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_app_overall_info, reset_app_overall_state } =
  AppOverallInfoSlice.actions;
