import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FooterContactLogoState {
  ID: string;
  logo: string;
  url: string;
}

export const footer_contactlogo_initial_state: FooterContactLogoState = {
  ID: "5a2",
  logo: "google",
  url: "google.com",
};

export const FooterContactLogoSlice = createSlice({
  name: "footer_contactlogo_info",
  initialState: footer_contactlogo_initial_state,
  reducers: {
    set_footer_contactlogo_info: (
      state,
      action: PayloadAction<Partial<FooterContactLogoState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    reset_footer_contactlogo_state: (state) => {
      Object.assign(state, footer_contactlogo_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_footer_contactlogo_info, reset_footer_contactlogo_state } =
  FooterContactLogoSlice.actions;
