import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";

export const SideTextBox = ({
  border,
  bg,
  textColor,
  title,
  description,
}: {
  border: string;
  bg: string;
  textColor: string;
  title: JSX.Element;
  description: JSX.Element;
}) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const MyBox = styled(Box)`
    border: ${border};
    background: ${bg};
  `;

  return (
    <>
      <MyBox className="px-1 px-md-3 w-100 h-100 py-2 py-md-5 d-flex flex-column align-items-center justify-content-center">
        <Typography
          className="mb-3"
          variant={is_mobile() ? "h6" : "h3"}
          style={{
            color: textColor,
          }}
        >
          {title}
        </Typography>
        <Typography
          className="text-uppercase"
          variant={is_mobile() ? "caption" : "h6"}
          style={{
            color: textColor,
          }}
        >
          {description}
        </Typography>
      </MyBox>
    </>
  );
};
