import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum AppGeneralInfoMediaTypes {
  image = "image",
  video = "video",
}

export enum AppGeneralInfoMediaVariants {
  logo_img = "logo_img",
}

export interface AppGeneralInfoSingleMediaEntity {
  type: AppGeneralInfoMediaTypes;
  url: string;
}

export interface AppGeneralInfoState {
  ID: string;
  main_logo_height: {
    mobile: string;
    large: string;
  };
  medias: {
    [key in AppGeneralInfoMediaVariants]: AppGeneralInfoSingleMediaEntity;
  };
}

export const app_general_info_initial_state: AppGeneralInfoState = {
  ID: "0e6e8292-2783-4d19-b20e-42d6acd52eed",
  main_logo_height: {
    mobile: "2.75rem",
    large: "3.75rem",
  },
  medias: {
    [AppGeneralInfoMediaVariants.logo_img]: {
      type: AppGeneralInfoMediaTypes.image,
      url: "logo-uplift-light.svg",
    },
  },
};

export const AppGeneralInfoSlice = createSlice({
  name: "app_general_info",
  initialState: app_general_info_initial_state,
  reducers: {
    set_app_general_info: (
      state,
      action: PayloadAction<Partial<AppGeneralInfoState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    reset_app_general_info_state: (state) => {
      Object.assign(state, app_general_info_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_app_general_info, reset_app_general_info_state } =
  AppGeneralInfoSlice.actions;
