import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BlogPostCategoryState {
  ID?: number;
  name: string;
  order: number;
  touched: boolean;
  is_error: boolean;
}

const blog_post_category_initial_state: BlogPostCategoryState = {
  ID: undefined,
  name: "",
  order: 0,
  touched: false,
  is_error: true,
};

function state_check(state: any) {
  state.is_error = !state.name;
}

export const BlogPostCategorySlice = createSlice({
  name: "blog_post_category",
  initialState: blog_post_category_initial_state,
  reducers: {
    set_blog_post_category_info: (
      state,
      action: PayloadAction<Partial<BlogPostCategoryState>>,
    ) => {
      state = Object.assign(state, action.payload);
      state_check(state);
    },
    reset_blog_post_category_state: (state) => {
      Object.assign(state, blog_post_category_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_blog_post_category_info, reset_blog_post_category_state } =
  BlogPostCategorySlice.actions;
