import React, { useEffect, useState } from "react";
import { HomeTopVideo } from "../FE_common/LandingPage/HomeTopVideo";
import { MyHomePageSingleBlock } from "../FE_common/LandingPage/HomePageSingleBlock";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../models/interface/gql_queries";
import { HomePageSingleBlockState } from "../../store/reducers/homepage_singleblock_info.reducer";
import {
  TopVideoBlockPositions,
  TopVideoState,
} from "../../store/reducers/topmedia_info.reducer";
import { HomepagePartnerSection } from "../FE_common/LandingPage/HomepagePartnerSection";
import { FooterOne } from "../FE_common/LandingPage/LandingPageFooter";
import { Container } from "react-bootstrap";
import { ServicesHomepageAvatars } from "./children/ServicesHomepageAvatar/ServicesHomepageAvatars";

const ServicesPageSecondSection = () => {
  const overall_information_query = useQuery(gql_queries.overall_information);

  const [all_blocks_data, set_all_blocks_data] = useState<
    HomePageSingleBlockState[] | null
  >(null);

  useEffect(() => {
    const raw_data = overall_information_query.data?.overall_information;
    if (raw_data && raw_data.service__socialmedia_page) {
      set_all_blocks_data(raw_data.service__socialmedia_page);
    }
  }, [overall_information_query]);

  return (
    <>
      {all_blocks_data ? (
        <>
          {all_blocks_data.map((item, i) => (
            <MyHomePageSingleBlock
              key={i}
              blockID={item.ID}
              target_page="service__socialmedia_page"
            />
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const Service__SocialMedia = () => {
  const overall_information_query = useQuery(gql_queries.overall_information);

  const [current_video, set_current_video] = useState<
    TopVideoState | undefined
  >(undefined);

  useEffect(() => {
    const data: TopVideoState[] =
      overall_information_query.data?.overall_information?.top_videos;

    if (!data) return;

    const found = data.find(
      (item) =>
        item.block_position == TopVideoBlockPositions.service__socialmedia_page,
    );
    if (!found || found == current_video) return;

    set_current_video(found);
  }, [overall_information_query.data]);

  return (
    <>
      {current_video ? (
        <>
          <HomeTopVideo top_video_info={current_video} />
          <Container
            fluid
            className="p-0"
            style={{
              overflowX: "hidden",
              width: "100vw",
            }}
          >
            <ServicesPageSecondSection />
            <HomepagePartnerSection />
            <ServicesHomepageAvatars />
            <FooterOne />
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
