import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppCoreLanguages } from "./app_core_state.reducer";

export enum TopVideoMediaTypes {
  image = "image",
  video = "video",
}

export interface TopVideoContent {
  title_topleft: string;
  title_bottomleft: string;
  main_text: string;
}

export interface TopVideoSingleMediaEntity {
  type: TopVideoMediaTypes;
  url: string;
}

export enum TopVideoMediaVariants {
  main_img = "main_img",
}

export enum TopVideoBlockPositions {
  homepage = "homepage",
  cases_page = "cases_page",
  services_page = "services_page",
  service__webdesign_page = "service__webdesign_page",
  service__socialmedia_page = "service__socialmedia_page",
  service__graphic_design_page = "service__graphic_design_page",
  service__nfc_card_page = "service__nfc_card_page",
  contact_page = "contact_page",
  blog_page = "blog_page",
  impressum_page = "impressum_page",
  privacy_page = "privacy_page",
  notfound_page = "notfound_page",
}

export type TopVideoState = {
  [key in AppCoreLanguages]: TopVideoContent;
} & {
  ID: string;
  block_position: TopVideoBlockPositions;
  medias: {
    [key in TopVideoMediaVariants]: TopVideoSingleMediaEntity;
  };
  prev_link: string;
  next_link: string;
};

const langContent = (): { [key in AppCoreLanguages]: TopVideoContent } => {
  const initVal: any = {};
  Object.values(AppCoreLanguages).forEach((item) => {
    Object.assign(initVal, {
      [item]: {
        title_topleft: "title",
        title_bottomleft: "title",
        main_text: "Main text",
      },
    });
  });
  return initVal;
};

export const topvideo_initial_state: TopVideoState = {
  ID: "5a2",
  block_position: TopVideoBlockPositions.homepage,
  ...langContent(),
  medias: {
    main_img: {
      type: TopVideoMediaTypes.image,
      url: "default_image_b34jk12.jpeg",
    },
  },
  prev_link: "/cases",
  next_link: "/cases",
};

export const TopVideoSlice = createSlice({
  name: "topvideo_info",
  initialState: topvideo_initial_state,
  reducers: {
    set_topvideo_info: (
      state,
      action: PayloadAction<Partial<TopVideoState>>,
    ) => {
      Object.assign(state, action.payload);
    },
    reset_topvideo_state: (state) => {
      Object.assign(state, topvideo_initial_state);
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_topvideo_info, reset_topvideo_state } =
  TopVideoSlice.actions;
