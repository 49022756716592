import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";
import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const BgOuterBox = ({
  bg,
  xlHeight,
  largeHeight,
  phoneHeight,
  smallPhoneHeight,
  tabletHeight,
  autoHeight,
}: {
  bg: string;
  xlHeight?: string;
  largeHeight?: string;
  phoneHeight?: string;
  tabletHeight?: string;
  autoHeight?: boolean;
  smallPhoneHeight?: string;
}) => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_xl() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return true;
      case all_screen_size.xxl:
        return true;
    }
  }

  function is_large() {
    switch (screen_size) {
      case all_screen_size.xs:
        return false;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return true;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function get_box_height() {
    if (autoHeight) return "unset";
    if (is_xl()) {
      if (xlHeight) {
        return xlHeight;
      } else {
        return "65vw";
      }
    }

    if (is_large()) {
      if (largeHeight) {
        return largeHeight;
      } else {
        return "180vw";
      }
    }
    if (is_small_phone()) {
      if (smallPhoneHeight) {
        return smallPhoneHeight;
      } else {
        return "200vw";
      }
    }
    if (is_phone()) {
      if (phoneHeight) {
        return phoneHeight;
      } else {
        return "340vw";
      }
    }
    if (tabletHeight) {
      return tabletHeight;
    } else {
      return "180vw";
    }
  }

  const Result = styled(Box)`
    width: 100vw;
    height: ${get_box_height()};
    background: ${bg};
    position: relative;
  `;

  return Result;
};
