import { configureStore } from "@reduxjs/toolkit";
import { Table_UI_Slice } from "./reducers/table_UI.reducer";
import { HomepageSingleBlockSlice } from "./reducers/homepage_singleblock_info.reducer";
import { AppOverallInfoSlice } from "./reducers/app_core_state.reducer";
import { screenSizeSlice } from "./reducers/screensize.reducer";
import { userInfoSlice } from "./reducers/userinfo.reducer";
import { TopVideoSlice } from "./reducers/topmedia_info.reducer";
import { CasesPageSingleBlockSlice } from "./reducers/cases_page_block_info.reducer";
import { HomepagePartnerSectionSlice } from "./reducers/homepage_partner_section_info.reducer";
import { FooterContactLogoSlice } from "./reducers/footer_contact_logo_info.reducer";
import { FooterPartnerLogoSlice } from "./reducers/footer_partner_info.reducer";
import { ContactPageInfoSlice } from "./reducers/contact_page_info.reducer";
import { BlogPostSlice } from "./reducers/blog_post_info.reducer";
import { BlogPostCategorySlice } from "./reducers/blog_post_category.reducer";
import { AppGeneralInfoSlice } from "./reducers/app_general_info.reducer";

export const reduxStore = configureStore({
  reducer: {
    table_UI: Table_UI_Slice.reducer,
    screenSize: screenSizeSlice.reducer,
    homepage_singleblock_info: HomepageSingleBlockSlice.reducer,
    cases_page_singleblock_info: CasesPageSingleBlockSlice.reducer,
    topvideo_info: TopVideoSlice.reducer,
    app_overall_info: AppOverallInfoSlice.reducer,
    userInfo: userInfoSlice.reducer,
    homepage_partner_section_info: HomepagePartnerSectionSlice.reducer,
    footer_contactlogo_info: FooterContactLogoSlice.reducer,
    footer_partnerlogo_info: FooterPartnerLogoSlice.reducer,
    contact_page_info: ContactPageInfoSlice.reducer,
    blog_post: BlogPostSlice.reducer,
    blog_post_category: BlogPostCategorySlice.reducer,
    app_general_info: AppGeneralInfoSlice.reducer,
  },
});
