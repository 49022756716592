import {
  BigBoxInner,
  Col5Absolute,
  Col7Absolute,
  LargeTextBox,
  LogoBox,
  SideBoxFrame,
} from "./others";
import { Col, Row } from "react-bootstrap";
import { RechtAngleImg } from "./RechtAngleImg";
import { MehrErfahrenBtn } from "./MehrErfahrenBtn";
import { SideTextBox } from "./SideTextBox";
import { SideImgBox } from "./SideImgBox";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../models/interface/gql_queries";
import { useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import {
  CasesPageSingleBlockModes,
  CasesPageSingleBlockState,
  CasesPageSingleBlockTypes,
} from "../../../store/reducers/cases_page_block_info.reducer";
import { cdnUrl } from "../../../environments/environments";
import { BgOuterBox } from "./BgOuterBox";
import { VerticalLines } from "./verticallines/vertical_lines";

export const CasesPageSingleBlock = (props: {
  blockID?: string;
  target_page?: string;
}) => {
  const overall_information_query = useQuery(gql_queries.overall_information);

  const initial_block_value = useSelector(
    (e: AppState) => e.cases_page_singleblock_info,
  );

  const [all_blocks_data, set_all_blocks_data] = useState<
    CasesPageSingleBlockState[] | null
  >(null);

  const [BlockInfo, setBlockInfo] =
    useState<CasesPageSingleBlockState>(initial_block_value);

  useEffect(() => {
    if (props.blockID) {
      const raw_data = overall_information_query.data?.overall_information;
      if (raw_data && props.target_page && raw_data[props.target_page]) {
        set_all_blocks_data(raw_data[props.target_page]);
      }
    }
  }, [overall_information_query]);

  useEffect(() => {
    if (all_blocks_data && props.blockID) {
      const foundBlock = all_blocks_data.find(
        (item) => item.ID == props.blockID,
      );
      if (foundBlock) setBlockInfo(foundBlock);
    }
  }, [all_blocks_data]);

  const language = useSelector((e: AppState) => e.app_overall_info.language);

  const MyBoxOne = () => (
    <>
      <LogoBox
        bg={
          BlockInfo.mode == CasesPageSingleBlockModes.light ? "#212121" : "#fff"
        }
        url={`${cdnUrl}/${BlockInfo.medias.logo_img.url}`}
      />
      <LargeTextBox
        bg="transparent"
        border={
          BlockInfo.mode == CasesPageSingleBlockModes.light
            ? "#212121 solid 1px"
            : "white solid 1px"
        }
        textCl={
          BlockInfo.mode == CasesPageSingleBlockModes.light ? "#212121" : "#fff"
        }
        title={<>{BlockInfo[language].title}</>}
        description={<>{BlockInfo[language].content}</>}
      />
    </>
  );

  const MyBoxTwo = () => (
    <>
      <Row
        className={`py-5 ${
          BlockInfo.type == CasesPageSingleBlockTypes.B
            ? "flex-md-row-reverse"
            : ""
        }`}
      >
        <Col xs="12" md="8" className="mb-5 mb-md-0">
          <Row className="h-100 flex-column justify-content-between gap-3">
            <Col>
              <RechtAngleImg
                url={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
              />
            </Col>
            <Col xs="auto">
              <MehrErfahrenBtn
                mainCl={
                  BlockInfo.mode == CasesPageSingleBlockModes.light
                    ? "#212121"
                    : "#fff"
                }
                secondCl={
                  BlockInfo.mode == CasesPageSingleBlockModes.light
                    ? "#fff"
                    : "#212121"
                }
                url={BlockInfo.see_more_link}
              />
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="4">
          <SideBoxFrame
            A={
              <SideTextBox
                textColor={
                  BlockInfo.mode == CasesPageSingleBlockModes.light
                    ? "#fff"
                    : "#212121"
                }
                bg={
                  BlockInfo.mode == CasesPageSingleBlockModes.light
                    ? "#212121"
                    : "#fff"
                }
                border="none"
                title={
                  <>
                    <b>{BlockInfo[language].first_number}</b>
                  </>
                }
                description={<>{BlockInfo[language].first_description}</>}
              />
            }
            B={
              <SideTextBox
                textColor={
                  BlockInfo.mode == CasesPageSingleBlockModes.light
                    ? "#212121"
                    : "#fff"
                }
                bg="transparent"
                border={
                  BlockInfo.mode == CasesPageSingleBlockModes.light
                    ? "#212121 solid 1px"
                    : "#fff solid 1px"
                }
                title={
                  <>
                    <b>{BlockInfo[language].second_number}</b>
                  </>
                }
                description={<>{BlockInfo[language].second_description}</>}
              />
            }
            C={
              <SideImgBox url={`${cdnUrl}/${BlockInfo.medias.small_img.url}`} />
            }
          />
        </Col>
      </Row>
    </>
  );

  const OuterBoxOne = BgOuterBox({
    bg:
      BlockInfo.mode == CasesPageSingleBlockModes.light ? "#f0f0f0" : "#212121",
    smallPhoneHeight: "350vw",
    xlHeight: "50rem",
  });
  const InnerBox = BigBoxInner({ fluid: false });

  return (
    <OuterBoxOne>
      <InnerBox>
        <Row
          className={`${
            BlockInfo.type == CasesPageSingleBlockTypes.B
              ? "flex-xl-row-reverse"
              : ""
          } justify-content-between align-items-center h-100 px-4 px-md-0`}
        >
          <Col5Absolute component={<MyBoxOne />} />
          <Col7Absolute component={<MyBoxTwo />} />
        </Row>
      </InnerBox>
      <VerticalLines
        bg={
          BlockInfo.mode == CasesPageSingleBlockModes.light
            ? "#212121"
            : "#f0f0f0"
        }
      />
    </OuterBoxOne>
  );
};
