import gsap from "gsap";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AppCoreLanguages } from "../../../store/reducers/app_core_state.reducer";
import { AppState } from "../../../store/models/corestore.interface";
import { useSelector } from "react-redux";

export const MehrErfahrenBtn = ({
  mainCl,
  secondCl,
  url,
}: {
  mainCl: string;
  secondCl: string;
  url: string;
}) => {
  function animated() {
    gsap.to(textRef.current, { x: 0 });
    gsap.to(firstArrowRef.current, { left: "150%" });
    gsap.to(secondArrowRef.current, { left: "50%" });
  }

  function animatedOut() {
    gsap.to(textRef.current, { x: "-50%" });
    gsap.to(firstArrowRef.current, { left: "50%" });
    gsap.to(secondArrowRef.current, { left: "-50%" });
  }

  const textRef = useRef<any>();
  const firstArrowRef = useRef<any>();
  const secondArrowRef = useRef<any>();

  const MyTextBox = styled(Box)`
    position: relative;
    width: 100%;
    border: ${mainCl} solid 1px;
    padding: 2rem 0;
    transition: all 0.2s ease-in-out;
    color: ${mainCl};

    :hover {
      color: ${secondCl};
      background: ${mainCl};
    }
  `;

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  const navigate = useNavigate();

  return (
    <Row
      onClick={() => navigate(`/${url}`)}
      className="c-pointer"
      onMouseEnter={() => animated()}
      onMouseLeave={() => animatedOut()}
    >
      <Col xs="10" className="pe-0">
        <MyTextBox>
          <Typography
            ref={textRef}
            component="span"
            className="text-uppercase"
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              left: "50%",
              top: "50%",
            }}
          >
            {(() => {
              switch (lang) {
                case AppCoreLanguages.de:
                  return "Mehr Erfahren";
                case AppCoreLanguages.vi:
                  return "See more";
                case AppCoreLanguages.en:
                  return "See more";
                case AppCoreLanguages.it:
                  return "Vedi altro";
              }
            })()}
          </Typography>
        </MyTextBox>
      </Col>
      <Col xs="2" className="ps-0">
        <Box
          className="position-relative overflow-hidden"
          style={{
            background: mainCl,
            width: "100%",
            height: "100%",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25 25"
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              left: "50%",
              top: "50%",
              width: "60%",
            }}
            ref={firstArrowRef}
          >
            <path
              style={{
                fill: secondCl,
              }}
              d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z"
              data-name="Right"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25 25"
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              left: "-50%",
              top: "50%",
              width: "60%",
            }}
            ref={secondArrowRef}
          >
            <path
              style={{
                fill: secondCl,
              }}
              d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z"
              data-name="Right"
            />
          </svg>
        </Box>
      </Col>
    </Row>
  );
};
