import CookieConsent from "react-cookie-consent";

export const CookieConsentSection = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept all"
      cookieName="cookie_consent_from_visitor"
      style={{ background: "#000" }}
      buttonStyle={{ color: "#000", background: "#fff", fontSize: "13px" }}
    >
      We use cookies to improve your web surfing experiences, more information
      about this please read our{" "}
      <a className="underline" href="/datenschutz">
        privacy policy
      </a>
      . By clicking accept, you agree with all our privacy policy strategy.
    </CookieConsent>
  );
};
