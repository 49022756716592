export interface UserInfo {
  ID: number;
  user_email: string;
  user_role: UserRoles;
}

export enum UserRoles {
  guest = "guest",
  subscriber = "subscriber",
  admin = "admin",
}
