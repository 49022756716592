import * as React from "react";
import Dialog from "@mui/material/Dialog";
import styled from "@emotion/styled";
import { BehaviorSubject, skipWhile, Subject, takeUntil } from "rxjs";
import { useEffect, useState } from "react";

const MyDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    overflow: "initial",
    maxWidth: "90vw",
    boxShadow: "none",
  },
}));

export const dialogContent = new BehaviorSubject<React.ReactElement>(<></>);
export const toggleDialog = new BehaviorSubject<boolean>(false);

export const UI_Dialog = () => {
  let destroy$: any;

  const [content, setContent] = useState<React.ReactElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    destroy$ = new Subject();

    toggleDialog.pipe(takeUntil(destroy$)).subscribe((res) => {
      setIsOpen(res);
    });
    dialogContent
      .pipe(
        takeUntil(destroy$),
        skipWhile((x) => !x),
      )
      .subscribe((res) => {
        setContent(res);
      });

    return () => {
      destroy$.next(false);
      destroy$.unsubscribe();
    };
  });

  return (
    <div>
      <MyDialog
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        onClose={() => {
          toggleDialog.next(false);
        }}
        sx={{
          "& *": {
            maxWidth: "100%",
          },
        }}
      >
        {content}
      </MyDialog>
    </div>
  );
};
