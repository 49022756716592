import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/models/corestore.interface";
import { all_screen_size } from "../../../store/reducers/screensize.reducer";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  HomepagePartnerSectionMediaTypes,
  HomepagePartnerSectionState,
  set_homepage_partner_section_info,
} from "../../../store/reducers/homepage_partner_section_info.reducer";
import { cdnUrl } from "../../../environments/environments";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../models/interface/gql_queries";
import { AppCoreLanguages } from "../../../store/reducers/app_core_state.reducer";

export const HomepagePartnerSection = () => {
  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const BlockInfo = useSelector(
    (e: AppState) => e.homepage_partner_section_info,
  );

  const dispatcher = useDispatch();

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  const lang = useSelector((e: AppState) => e.app_overall_info.language);

  useEffect(() => {
    const data: HomepagePartnerSectionState =
      overall_information_query.data?.overall_information
        ?.homepage_partner_section;

    if (!data) return;

    dispatcher(set_homepage_partner_section_info(data));
  }, [overall_information_query]);

  return (
    <>
      <Box
        className="position-relative overflow-hidden py-5"
        style={{
          minHeight: is_mobile() ? "80vh" : "400px",
          width: "100vw",
          display: "none",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Typography variant="h6" className="text-uppercase text-white">
                {(() => {
                  switch (lang) {
                    case AppCoreLanguages.de:
                      return "Unsere Kunden";
                    case AppCoreLanguages.vi:
                      return "Danh sách khách hàng";
                    case AppCoreLanguages.en:
                      return "Our customers";
                    case AppCoreLanguages.it:
                      return "I nostri clienti";
                  }
                })()}
              </Typography>
            </Col>
          </Row>
          <Row>
            {Object.keys(BlockInfo.medias).map((item, i) => (
              <React.Fragment key={i}>
                {!item.includes("img_") || !BlockInfo.medias[item].url ? (
                  <div />
                ) : (
                  <Col
                    xs="12"
                    md="6"
                    lg="4"
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      minHeight: 300,
                    }}
                  >
                    <img
                      src={`${cdnUrl}/${BlockInfo.medias[item].url}`}
                      width="150"
                      height="auto"
                    />
                  </Col>
                )}
              </React.Fragment>
            ))}
          </Row>
        </Container>
        {BlockInfo.medias.main_img.type ==
        HomepagePartnerSectionMediaTypes.image ? (
          <>
            <img
              src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
              style={{
                minWidth: "100%",
                minHeight: "100%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: -10,
                objectFit: "cover",
              }}
            />
          </>
        ) : (
          <>
            <video
              autoPlay
              loop
              preload="metadata"
              playsInline
              muted
              style={{
                minWidth: "100%",
                minHeight: "100%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: -10,
                objectFit: "cover",
              }}
              data-hd="true"
            >
              <source
                src={`${cdnUrl}/${BlockInfo.medias.main_img.url}`}
                type="video/mp4"
              />
              {`Your browser doesn't support HTML5 video tag.`}
            </video>
          </>
        )}
        <Box
          style={{
            minWidth: "100%",
            minHeight: "100%",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: -5,
            objectFit: "cover",
            background: "#0000003b",
          }}
        />
      </Box>
    </>
  );
};
