import { Box, Typography } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import { VerticalLines } from "../../../FE_common/LandingPage/verticallines/vertical_lines";
import { HomePageSingleBlockState } from "../../../../store/reducers/homepage_singleblock_info.reducer";
import { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { gql_queries } from "../../../../models/interface/gql_queries";
import { externalCdnUrl } from "../../../../environments/environments";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/models/corestore.interface";
import { all_screen_size } from "../../../../store/reducers/screensize.reducer";
import { useNavigate } from "react-router-dom";
import { AppCoreLanguages } from "../../../../store/reducers/app_core_state.reducer";
import styled from "@emotion/styled";

export const ServicesHomepageAvatars = () => {
  const lang = useSelector((e: AppState) => e.app_overall_info.language);
  const navigate = useNavigate();

  const screen_size = useSelector((e: AppState) => e.screenSize.value);

  function is_small_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return false;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_phone() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return false;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  function is_mobile() {
    switch (screen_size) {
      case all_screen_size.xs:
        return true;
      case all_screen_size.sm:
        return true;
      case all_screen_size.md:
        return true;
      case all_screen_size.lg:
        return false;
      case all_screen_size.xl:
        return false;
      case all_screen_size.xxl:
        return false;
    }
  }

  const overall_information_query = useQuery(gql_queries.overall_information, {
    fetchPolicy: "cache-and-network",
  });

  const [services_page_blocks, set_services_page_blocks] = useState<
    HomePageSingleBlockState[]
  >([]);

  useEffect(() => {
    const data = overall_information_query.data?.overall_information;

    if (data && data.services_page_blocks) {
      set_services_page_blocks(data.services_page_blocks);
    }
  }, [overall_information_query.data]);

  const MyImgBox = styled(Box)`
    transition-duration: 0.5s;
    :hover {
      transform: translateY(-10px);
    }
  `;

  return (
    <>
      <Container
        fluid
        style={{
          background: "#f0f0f0",
          position: "relative",
          padding: 0,
        }}
      >
        <Container
          style={{
            padding: is_mobile() ? "8rem 1rem" : "6rem 0 16rem",
          }}
        >
          <Row>
            <Col className="z-10">
              <Row className="mb-4">
                <Col>
                  <Typography
                    variant={is_mobile() ? "h4" : "h2"}
                    fontWeight="bold"
                    className="text-center text-uppercase mb-5"
                  >
                    {(() => {
                      switch (lang) {
                        case AppCoreLanguages.de:
                          return "Services";
                        case AppCoreLanguages.vi:
                          return "Các dịch vụ";
                        case AppCoreLanguages.en:
                          return "All services";
                        case AppCoreLanguages.it:
                          return "Tutti i servizi";
                      }
                    })()}
                  </Typography>
                </Col>
              </Row>
              <Box>
                <section>
                  <Row>
                    {services_page_blocks.map((item, i) => (
                      <Fragment key={i}>
                        {i <= 3 ? (
                          <>
                            <Col
                              xs="6"
                              lg="3"
                              className="p-3"
                              onClick={() =>
                                navigate(`/services/${item.service_url}`)
                              }
                            >
                              <MyImgBox
                                style={{
                                  width: "100%",
                                  cursor: "pointer",
                                  height: is_small_phone()
                                    ? 150
                                    : is_phone()
                                    ? 200
                                    : 300,
                                  boxShadow:
                                    "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                  background: `url("${externalCdnUrl}/${item.medias.homepage_img.url.replace(
                                    "overall_information/",
                                    "",
                                  )}") no-repeat center center / cover`,
                                }}
                              />
                              <div className="card__content">
                                {/*<p className="card__category">Category</p>*/}
                                {/*<h3 className="card__heading">*/}
                                {/*  Example Card Heading*/}
                                {/*</h3>*/}
                              </div>
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                      </Fragment>
                    ))}
                  </Row>
                </section>
              </Box>
            </Col>
          </Row>
        </Container>
        <VerticalLines bg="#212121" />
      </Container>
    </>
  );
};
