import { gql } from "@apollo/client";

export const gql_queries = {
  overall_information: gql(`
    query overall_information {
      overall_information
    }
  `),
  user_info: gql(`
    query user_info {
      user_info {
        ID,
        user_email,
        user_role
      }
    }
  `),
  all_blog_posts: gql(`
      query all_blog_posts {
        all_blog_posts {
          ID,
          category,
          de,
          en,
          vi,
          it,
          date,
          user_ID,
          avatar {
              media_name,
              media_path
          },
          author {
            ID,
            user_email,
          }
        }
      }
    `),
  all_blog_post_categories: gql(`
    query all_blog_post_categories {
      all_blog_post_categories {
        ID,
        name,
        order,
        color
      }
    }
  `),
  all_users: gql(`
    query all_users {
      all_users {
        ID,
        user_email,
        user_role
      }
    }
  `),
};
