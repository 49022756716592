import React, { useEffect, useState } from "react";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { Snackbar } from "@mui/material";
import html_parser from "html-react-parser";
import styled from "@emotion/styled";

export const toggleSnackbar = new BehaviorSubject<string | null>("");

export const UI_Snackbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  let destroy$: any;

  useEffect(() => {
    destroy$ = new Subject();

    toggleSnackbar.pipe(takeUntil(destroy$)).subscribe((res) => {
      if (res && res !== "") {
        setIsOpen(true);
        setMessage(res);
      } else {
        setIsOpen(false);
      }
    });
    return () => {
      destroy$.next(false);
      destroy$.unsubscribe();
    };
  });

  const MySnackbar = styled(Snackbar)`
    ul {
      padding-left: 1rem;
      margin-bottom: 0;
    }
  `;

  return (
    <MySnackbar
      open={isOpen}
      autoHideDuration={3000}
      message={html_parser(message)}
      onClose={() => toggleSnackbar.next("")}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    />
  );
};
